import { message, Table } from "antd";
import React, { memo, useCallback, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { CONFIG_PAGINATION } from "../constants";

import { useDispatch, useSelector } from "react-redux";
import UtilBar from "./components/UtilBar";
import "./style.scss";

import { isFunction } from "lodash";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useActionColumn, useTableCols } from "../";
import { setIsFullscreen } from "../../../app/common";
import { confirmDelete } from "../../../utils";
import { openDrawer, setLocale } from "../../Drawer/slice/drawer";
import { onChangeTable } from "../constants";
import { setTableColumns } from "../Slice/tableSlice";

// Config fullscreen for message
message.config({
  getContainer: (node) => {
    return node ? document.body : document.querySelector(".table-fullscreen");
  },
});

const TableMain = ({
  getCheckboxProps,
  cols,
  nth,
  nthBy,
  items,
  titleLabel,
  idKey = "id",
  filter,
  totalRecord,
  Filter,
  handleFilter,
  loadingTable,
  setFilter,
  fetchData,
  transformColsForExport,
  buttons,
  leftButtons,
  addBtnLabel,
  modalAddTitle,
  modalEditTitle,
  deleteManyApi,
  deleteContentKey = "title",
  getApi,
  excelName,
  title,
  scroll = { x: "max-content" },
  onRow,
  pagination,
  disableHeader = false,
  rowSelection: rowSelectionProps,
  //takes an array of rows as params and return void
  onDelete,
  onEdit,
  onDetail,
  onCreate,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Delete many
  const [selects, setSelects] = useState([]);
  // Columns
  const { columns } = useSelector((state) => state.table);
  const setColumns = useCallback(
    (payload) => dispatch(setTableColumns(payload)),
    [dispatch]
  );
  // Fullscreen
  const { isFullscreen } = useSelector((state) => state.common);
  const handleFullScreen = useFullScreenHandle();
  const handleOnchangeFullscreen = useCallback(
    (state) => {
      dispatch(setIsFullscreen(state));
    },
    [dispatch]
  );

  const getAddTitle = () => {
    const mainTitle = title?.toLowerCase();
    if (addBtnLabel) {
      return `${addBtnLabel} ${mainTitle}`;
    }
    return modalAddTitle || t("table.createTitle", { title: mainTitle });
  };

  const allowCreate = onCreate ?? true;
  const handleAdd = useCallback(() => {
    dispatch(openDrawer());
    dispatch(
      setLocale({
        btn: `${t("common.create")}`,
        title: getAddTitle(),
        successMsg: `Create ${t("common.successful")}`,
      })
    );
    // eslint-disable-next-line
  }, [dispatch, t]);

  const allowEdit = Boolean(onEdit ?? true);
  const handleEdit = useCallback(
    (record) => {
      if (isFunction(onEdit)) {
        onEdit(record);
      } else {
        dispatch(openDrawer(record));
        dispatch(
          setLocale({
            btn: t("common.edit"),
            title:
              modalEditTitle ||
              t("table.editTitle", { title: title?.toLowerCase() }),
            successMsg: `Update ${t("common.successful")}`,
          })
        );
      }
    },
    [dispatch, t, modalEditTitle, onEdit, title]
  );

  const allowDelete = Boolean(onDelete ?? isFunction(deleteManyApi));
  const handleDelete = useCallback(
    (ids) => {
      if (Array.isArray(ids)) {
        if (isFunction(onDelete)) {
          onDelete(ids);
        } else {
          const content = ids.reduce((prev, curr) => {
            if (prev) {
              prev += ", ";
            }
            return (
              prev +
              items?.find((item) => item[idKey] === curr)?.[deleteContentKey]
            );
          }, "");
          confirmDelete({
            handleDelete: async () => {
              await deleteManyApi(ids);
              if (ids.length > 1) {
                setSelects([]);
              } else {
                setSelects((prev) => prev.filter((value) => value !== ids[0]));
              }
              fetchData();
            },
            content,
          });
        }
      }
    },
    // eslint-disable-next-line
    [deleteContentKey, idKey, items]
  );

  const actions = useMemo(() => {
    const actions = { onDetail };
    if (allowEdit) {
      actions.onEdit = handleEdit;
    }
    if (allowDelete) {
      actions.onDelete = (record) => handleDelete([record?.[idKey]]);
    }

    return actions;
    // eslint-disable-next-line
  }, [handleEdit, handleDelete, onDetail]);

  // Actions have delete and edit
  const actionCol = useActionColumn(actions);
  useTableCols({
    cols,
    nth,
    titleLabel,
    filter,
    actionCol,
  });

  const rowSelection = useMemo(() => {
    return isFunction(deleteManyApi) && leftButtons === undefined
      ? {
          selectedRowKeys: selects,
          onChange: setSelects,
          getCheckboxProps,
          renderCell(_value, _record, _index, originNode) {
            return (
              <div
                onDoubleClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {originNode}
              </div>
            );
          },
          ...rowSelectionProps,
        }
      : rowSelectionProps;
  }, [
    deleteManyApi,
    selects,
    getCheckboxProps,
    rowSelectionProps,
    leftButtons,
  ]);

  const tablePagination = useMemo(() => {
    if (pagination !== false) {
      return {
        ...CONFIG_PAGINATION,
        pageSize: filter?.limit,
        total: totalRecord,
        current: filter?.currentPage,
        ...pagination,
      };
    }
    return pagination;
  }, [pagination, totalRecord, filter]);

  return (
    <div className="box-list table-main">
      <div className="box-shadow">
        <FullScreen
          handle={handleFullScreen}
          className="table-fullscreen"
          onChange={handleOnchangeFullscreen}
        >
          {isFullscreen && Filter && (
            <Filter
              filter={filter}
              setFilter={setFilter}
              submit={handleFilter}
            />
          )}
          {!disableHeader && (
            <UtilBar
              filter={{ ...filter, limit: totalRecord }}
              transformColsForExport={transformColsForExport}
              addBtnLabel={addBtnLabel}
              handleFullScreen={handleFullScreen}
              totalRecord={totalRecord}
              getApi={getApi}
              buttons={buttons}
              leftButtons={leftButtons}
              excelName={excelName}
              onDelete={
                allowDelete &&
                selects.length > 0 &&
                (() => handleDelete(selects))
              }
              onCreate={allowCreate && handleAdd}
            />
          )}
          <Table
            getPopupContainer={(node) => node.parentNode}
            dataSource={items}
            columns={columns.filter((e) => e.show)}
            loading={loadingTable}
            onChange={onChangeTable(
              filter,
              setFilter,
              columns,
              setColumns,
              setSelects,
              nth,
              nthBy
            )}
            rowSelection={rowSelection}
            rowKey={idKey}
            locale={{
              triggerDesc: t("table.triggerDesc"),
              triggerAsc: t("table.triggerAsc"),
              cancelSort: t("table.cancelSort"),
            }}
            pagination={tablePagination}
            scroll={scroll}
            rowClassName={onDetail || allowEdit ? "cursor-pointer" : ""}
            onRow={(data, i) => {
              const rowProps = onRow?.(data, i) ?? {};
              rowProps.onDoubleClick = () => {
                if (onDetail) {
                  onDetail?.(data);
                } else if (allowEdit) {
                  handleEdit(data);
                }
              };
              return rowProps;
            }}
            {...props}
          />
        </FullScreen>
      </div>
    </div>
  );
};

export default memo(TableMain);
