import React from "react";
import { useTranslation } from "react-i18next";
import contractTypeApi from "../../../api/contractTypeApi";
import { HeaderLayout } from "../../../components/Layouts";
import { TableMain, useTable } from "../../../components/Table";
import ModalForm from "./components/ModalForm";
import { cols } from "./constants";

export default function ContractType() {
  const { t } = useTranslation();

  const { items, filter, setFilter, loadingTable, fetchData, totalRecord } =
    useTable({
      getApi: contractTypeApi.getAll,
    });

  return (
    <HeaderLayout title={t("contractType.mainTitle")}>
      <ModalForm
        fetchData={fetchData}
        addApi={contractTypeApi.add}
        editApi={contractTypeApi.edit}
        isPagination={false}
        setFilter={setFilter}
      />
      <TableMain
        items={items}
        cols={cols}
        filter={filter}
        setFilter={setFilter}
        fetchData={fetchData}
        getApi={contractTypeApi.getAll}
        deleteManyApi={contractTypeApi.deleteMany}
        loadingTable={loadingTable}
        pagination={false}
        deleteContentKey="contractType"
        totalRecord={totalRecord}
        excelName="list-contractType"
        title={t("contractType.contractType")}
      />
    </HeaderLayout>
  );
}
