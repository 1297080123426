import { message } from "antd";
import i18n from "../../../../../translation/i18n";
import { deepCopy } from "../../../../../utils";
import {
	DEFAULT_STYLE_EXCEL,
	DEFAULT_LIMIT_RECORDS_EXPORT,
} from "../../../constants";
import { Excel } from "antd-table-saveas-excel";
import { isFunction } from "lodash";

export const transformCols = (columns, preHandleCols) => {
	let newColumns = deepCopy(columns);
	if (isFunction(preHandleCols)) {
		newColumns = preHandleCols(newColumns);
	}

	newColumns = newColumns
		.filter((col) => col.export && col.show)
		.map((col) => {
			if (col.colType === "status" && col.dataIndex === "status") {
				col.render = (status) =>
					status ? i18n.t("common.active") : i18n.t("common.inactive");
			}
			if (col.colType === "tooltip") {
				col.render = undefined;
			}
			if (col?.width) delete col.width;
			return col;
		});
	return newColumns;
};

export const exportToExcel = async ({
	columns,
	excelName,
	totalRecords,
	listParams,
	fetchApi,
	pageNumber = 1,
}) => {
	try {
		if (totalRecords) {
			message.loading({
				content: i18n.t("message.loadingData", {
					content: `${pageNumber} / ${Math.ceil(
						totalRecords / DEFAULT_LIMIT_RECORDS_EXPORT
					)}`,
				}),
				key: "export-request",
				duration: 0,
			});
			const newParams = {
				...listParams,
				currentPage: pageNumber,
				limit: totalRecords,
			};
			// call api with full record ( limit = totalRecords )
			let excelItems;
			if (fetchApi) {
				const response = await fetchApi(newParams);
				excelItems = response?.data?.data;
			}
			if (excelItems && excelItems.length > 0) {
				const excel = new Excel();
				// set font family to excel file
				excel
					.setTBodyStyle(DEFAULT_STYLE_EXCEL)
					.setTHeadStyle(DEFAULT_STYLE_EXCEL);

				excel
					.addSheet(excelName)
					.addColumns(columns)
					.addDataSource(excelItems)
					.saveAs(`${excelName}.xlsx`);

				pageNumber++;
				if (
					pageNumber <= Math.ceil(totalRecords / DEFAULT_LIMIT_RECORDS_EXPORT)
				) {
					exportToExcel({
						columns,
						excelName,
						totalRecords,
						listParams,
						fetchApi,
						pageNumber,
					});
				} else {
					message.success({
						content: i18n.t("message.exportSuccess"),
						key: "export-request",
						duration: 2,
					});
				}
			}
		} else {
			message.error(i18n.t("message.nothingToExport"));
		}
	} catch (error) {
		console.log("error :", error);
		message.error({
			content: i18n.t("message.failedToFetch"),
			key: "export-request",
			duration: 3,
		});
	}
};
