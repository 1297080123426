import { Drawer, Form, message, Modal } from "antd";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { deepCopy } from "../../utils/object";
import { uppercaseFirstLetters } from "../../utils/string";
import { SaveButton } from "../Buttons";
import { SubmitBtn } from "../Form";
import { DEFAULT_FILTER } from "../Table/constants";
import { setTableColumns } from "../Table/Slice/tableSlice";
import { closeDrawer } from "./slice/drawer";
import "./styles.scss";
import { isFunction } from "lodash";

const PopUp = memo(
	({
		children,
		modal,
		title,
		onClose,
		visible,
		bodyStyle,
		getContainer,
		width,
		className,
	}) => {
		return modal ? (
			<Modal
				title={title}
				open={visible}
				onCancel={onClose}
				footer={null}
				centered
				className="addCv custom-modal"
				bodyStyle={bodyStyle}
				width={width || "70%"}
				getContainer={getContainer}>
				{children}
			</Modal>
		) : (
			<Drawer
				title={title}
				placement="right"
				onClose={onClose}
				open={visible}
				forceRender
				width={width || "20%"}
				zIndex={2000}
				className={className}
				getContainer={getContainer}>
				{children}
			</Drawer>
		);
	}
);

export default function GeneralDrawer({
	children,
	form,
	onFinish,
	fetchData,
	fillData,
	addApi,
	setFilter,
	isPagination,
	editApi,
	onFormChange,
	idKey,
	catchCallback,
	disabled,
	transformValues,
	minHeight = "65vh",
	width = "30%",
	modal = true,
	fullscreenClassName,
	requiredFields = [],
	className,
}) {
	const bodyStyle = {};
	if (minHeight) {
		bodyStyle.minHeight = minHeight;
	}
	const { t } = useTranslation();
	const { isOpened, locale, isEditMode, initial } = useSelector(
		(state) => state.drawer
	);
	const { columns } = useSelector((state) => state.table);
	const { isFullscreen } = useSelector((state) => state.common);
	const dispatch = useDispatch();
	const [loadingBtn, setLoadingBtn] = useState(false);

	const handleClose = useCallback(() => {
		form.resetFields();
		dispatch(closeDrawer());
	}, [form, dispatch]);

	useEffect(() => {
		if (isFunction(fillData)) {
			fillData();
		} else {
			form.setFieldsValue(initial);
		}
		// missing fillData
		// eslint-disable-next-line
	}, [isOpened, form]);

	const onFinishDefault = async (values) => {
		if (!isEditMode && addApi) {
			await addApi(values);
		}
		const id = initial?.[idKey] ?? initial?.id;
		if (isEditMode && editApi && id) {
			await editApi(id, values);
		}
	};

	const successMsg = useMemo(() => {
		if (locale.successMsg) {
			return locale.successMsg;
		} else {
			return isEditMode ? t("message.success") : t("message.createSuccess");
		}
	}, [locale.successMsg, isEditMode, t]);

	const onFinishForm = useCallback(
		async (values) => {
			setLoadingBtn(true);
			try {
				for (let key in values) {
					if (typeof values[key] === "string") values[key] = values[key].trim();
				}
				if (transformValues) values = transformValues(values);
				const finish = onFinish || onFinishDefault;
				await finish(values);
				if (!isEditMode && setFilter && isPagination) {
					setFilter(DEFAULT_FILTER);
					const newColumns = deepCopy(columns);
					newColumns.forEach((col) => {
						col.sortOrder = false;
						return col;
					});
					dispatch(setTableColumns(newColumns));
				}
				fetchData();
				handleClose();
				setLoadingBtn(false);
				message.success(successMsg);
			} catch (e) {
				setLoadingBtn(false);
				if (catchCallback) {
					catchCallback(e);
				} else {
					message.error(e?.response?.data?.message ?? e.message);
				}
				console.log(e);
			}
		},
		//missing 'catchCallback',  'fetchData', 'onFinishDefault', 'setFilter', and 'transformValues
		// eslint-disable-next-line
		[
			dispatch,
			t,
			isEditMode,
			onFinish,
			successMsg,
			columns,
			handleClose,
			isPagination,
			initial,
		]
	);

	return (
		<PopUp
			modal={modal}
			title={uppercaseFirstLetters(locale.title)}
			placement="right"
			onClose={handleClose}
			visible={isOpened}
			bodyStyle={bodyStyle}
			forceRender
			width={width}
			zIndex={2000}
			className={className}
			getContainer={
				isFullscreen
					? document.querySelector(
							`.${fullscreenClassName || "table-fullscreen"}`
					  )
					: document.body
			}>
			<Form
				form={form}
				layout="vertical"
				className="d-flex standard-form"
				onFinish={onFinishForm}
				disabled={disabled}
				style={{ display: "flex", flexDirection: "column", height: "100%" }}
				onValuesChange={onFormChange}>
				<div className="content-wrapper">{children}</div>
				<SubmitBtn
					style={{ marginTop: "auto", marginBottom: 0 }}
					form={form}
					loading={loadingBtn}
					requiredFields={requiredFields}
					Button={SaveButton}
					disabled={disabled}
					icon={locale.icon}>
					{locale.btn}
				</SubmitBtn>
			</Form>
		</PopUp>
	);
}
