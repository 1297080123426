import { DatePicker, Form, Input, Select } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { FORMAT_DATE } from "../constants";
export default function OptionsField({
	hidden,
	noStyle,
	label,
	name,
	placeholder,
	rules = [],
	shouldUpdate = (prev, curr) => prev[name] !== curr[name],
	formatDisplay = (value) => JSON.parse(JSON.stringify(value)),
	disabled,
	field,
	suffix,
	mode = "none",
	type,
	normalize,
	children,
	disabledYear,
	...props
}) {
	const visible = useSelector((state) => state.common.visible);
	const TypeCase = ({ setFieldsValue }) => {
		switch (type) {
			case "input": {
				return (
					<Input
						disabled={disabled}
						data-testid={`${name}_input`}
						{...props}
						onBlur={(e) => {
							if (e.target?.value) {
								setFieldsValue({ [name]: e.target.value.trim() });
							}
						}}
					/>
				);
			}
			case "select": {
				return (
					<Select
						key={name}
						disabled={disabled}
						mode={mode}
						className="w-full"
						tokenSeparators={[","]}>
						{children}
					</Select>
				);
			}
			case "datePicker": {
				return (
					<DatePicker
						className="w-full"
						format={FORMAT_DATE}
						disabledDate={disabledYear}
					/>
				);
			}
			default:
		}
	};

	return visible ? (
		<Form.Item noStyle hidden={hidden} shouldUpdate={shouldUpdate}>
			{({ setFieldsValue, isFieldTouched }) => (
				<Form.Item
					noStyle={noStyle}
					label={label}
					labelCol={{ span: 5, offset: 1 }}
					rules={isFieldTouched(name) ? rules : undefined}
					name={name}
					shouldUpdate={false}
					{...field}
					normalize={normalize}
					key={name}>
					{TypeCase({ setFieldsValue })}
				</Form.Item>
			)}
		</Form.Item>
	) : (
		<Form.Item
			noStyle={noStyle}
			label={label}
			shouldUpdate={shouldUpdate}
			rules={rules}
			key={name}
			labelCol={{ span: 5, offset: 1 }}
			{...field}>
			{({ getFieldValue }) => {
				if (getFieldValue(name)) {
					return (
						<div className="items-center">
							{formatDisplay(getFieldValue(name))}
						</div>
					);
				}
			}}
		</Form.Item>
	);
}
