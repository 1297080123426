import { Form } from "antd";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";

function FormItem({
	children,
	name,
	required,
	label,
	min,
	type,
	style,
	className,
	customType,
	rules,
}) {
	const props = { name, style, className };
	const lowerLabel = label.toLowerCase();

	const { t } = useTranslation();

	const labelValue = required ? (
		<span className="field--required">
			{label} (<span>*</span>)
		</span>
	) : (
		label
	);

	const rulesFormItem = rules ? [...rules] : [];
	if (required) {
		const placeholderText =
			type === "select"
				? t("common.select").toLowerCase()
				: t("common.type").toLowerCase();
		const message = `${t("common.please")} ${placeholderText} ${lowerLabel}`;

		const obj = {
			required: true,
			message,
		};

		if (type !== "select" && customType !== "number") obj.whitespace = true;
		rulesFormItem.push(obj);
	}

	if (min)
		rulesFormItem.push({
			validator(_, value) {
				value = value?.trim();
				if (value !== "" && value?.length < min)
					return Promise.reject(
						new Error(t("form.minMessage", { label, min }))
					);
				return Promise.resolve();
			},
		});

	// useEffect(() => {
	// 	if (name === "newPosition") {
	// 		console.log("mounted");
	// 	}
	// }, []);

	return (
		<Form.Item {...props} rules={rulesFormItem} label={labelValue}>
			{children}
		</Form.Item>
	);
}

export default memo(FormItem);
