import axiosClient from "./axiosClient";
import { EMPLOYEE_CAREER_API_URL } from "./constants";
const employeeCareerApi = {
	getAll(params, id) {
		return axiosClient.get(`${EMPLOYEE_CAREER_API_URL}/${id}`, { params });
	},
	getAllStandard(id) {
		return (params) => this.getAll(params, id);
	},
	addAndEdit(id) {
		return (data) => axiosClient.post(`${EMPLOYEE_CAREER_API_URL}/${id}`, data);
	},
};

export default employeeCareerApi;
