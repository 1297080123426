import i18n from "../../../translation/i18n";

export const getBreadcrumbs = (id, fullName) => [
	{
		link: "/employee",
		label: i18n.t("employeeLayout.employeeBreadcrumb"),
	},
	{
		link: `/employee/${id}`,
		label: fullName,
	},
];
