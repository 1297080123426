import { Switch } from "antd";
import React from "react";
import FormItem from "./FormItem";
import "./scss/formDescriptionSwitch.scss";

function CustomSwitch({
	onChange,
	value,
	checkedDescription,
	uncheckDescription,
	descriptionStyle,
}) {
	const onChangeSwitch = (e) => {
		if (e === false) e = 0;
		if (e === true) e = 1;
		onChange(e);
	};
	return (
		<>
			<div
				className="switch-description"
				style={{ marginLeft: 10, fontSize: 13, ...descriptionStyle }}>
				{value ? checkedDescription : uncheckDescription}
			</div>
			<Switch checked={value} onChange={onChangeSwitch} />
		</>
	);
}

export default function FormDescriptionSwitch({
	checkedDescription,
	uncheckDescription,
	descriptionStyle,
	...formItemProps
}) {
	const switchProps = {
		checkedDescription,
		uncheckDescription,
		descriptionStyle,
	};

	return (
		<FormItem {...formItemProps} className="description-switch">
			<CustomSwitch {...switchProps} />
		</FormItem>
	);
}
