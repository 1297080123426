import { UserOutlined } from "@ant-design/icons";
import { Avatar, Image } from "antd";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useColumns() {
  const { t } = useTranslation();

  return useMemo(() => {
    const columns = [
      {
        title: "",
        dataIndex: "avatarUri",
        show: true,
        width: "5%",
        render: (url) => {
          if (url === null) {
            return <Avatar icon={<UserOutlined />} />;
          } else {
            return <Avatar src={<Image preview={false} src={url} />} />;
          }
        },
      },
      {
        type: "all",
        title: t("employees.fullName"),
        dataIndex: "fullName",
      },
      {
        type: "all",
        title: t("employees.id"),
        dataIndex: "id",
      },
      {
        type: "allWithoutSort",
        title: t("employees.department"),
        dataIndex: "department",
      },
      {
        type: "allWithoutSort",
        title: t("position.t"),
        dataIndex: "position",
      },
      {
        type: "allWithoutSort",
        title: t("rank.t"),
        dataIndex: "rank",
      },
      {
        type: "allWithoutSort",
        title: t("employees.email"),
        dataIndex: "email",
      },
      {
        type: "all",
        title: t("employees.startDate"),
        dataIndex: "startDate",
      },
      {
        type: "status",
      },
    ];
    return columns;
  }, [t]);
}
