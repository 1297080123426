import axiosClient from "./axiosClient";
import {
	EMPLOYEE_API_URL,
	EMPLOYEE_UPLOAD_API_URL,
	SYNC_EMPLOYEES,
} from "./constants";
const employeeApi = {
	getAll(params) {
		return axiosClient.get(EMPLOYEE_API_URL, { params });
	},
	getById(id) {
		return axiosClient.get(`${EMPLOYEE_API_URL}/${id}`);
	},
	edit(id, data) {
		return axiosClient.put(`${EMPLOYEE_API_URL}/${id}`, data);
	},
	editAvatar(id, file) {
		const formData = new FormData();
		formData.append("avatar", file);
		return axiosClient.put(`${EMPLOYEE_UPLOAD_API_URL}/${id}`, formData);
	},
	syncUser() {
		return axiosClient.put(SYNC_EMPLOYEES);
	},
};

export default employeeApi;
