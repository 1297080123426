import React, { useState } from "react";
import { PositionSelect, RankSelect } from "../../../../components/Form";
import { names } from "../constants";
import { Form } from "antd";

function PositionAndRankSelections() {
	const form = Form.useFormInstance();
	const [selectedPositionRanks, setSelectedPositionRanks] = useState([]);

	return (
		<>
			<PositionSelect
				name={names.newPosition}
				onChange={(_, option) => {
					setSelectedPositionRanks(option?.rank);
					form.resetFields([names.newRank]);
				}}
				required
			/>
			<RankSelect
				name={names.newRank}
				hiddenKey={(rank) => !selectedPositionRanks?.includes(rank.id)}
				required
			/>
		</>
	);
}

export default PositionAndRankSelections;
