import React from "react";
import { useTranslation } from "react-i18next";
import employeeApi from "../../../api/employeeApi";
import { HeaderLayout } from "../../../components/Layouts";
import { TableMain, useTable } from "../../../components/Table";
import ListFilter from "./components/ListFilter";
import SyncBtn from "./components/SyncBtn";
import useColumns from "./useColumns";
import { useNavigate } from "react-router-dom";

function EmployeeList() {
  const cols = useColumns();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { items, filter, setFilter, loadingTable, fetchData, totalRecord } =
    useTable({
      getApi: employeeApi.getAll,
    });

  return (
    <HeaderLayout
      title={t("employees.employees")}
      button={<SyncBtn refetch={fetchData} />}
    >
      <ListFilter filter={filter} setFilter={setFilter} />
      <TableMain
        idKey="id"
        items={items}
        cols={cols}
        filter={filter}
        setFilter={setFilter}
        fetchData={fetchData}
        getApi={employeeApi.getAll}
        loadingTable={loadingTable}
        totalRecord={totalRecord}
        excelName="list-position"
        title={t("employees.employees")}
        leftButtons={false}
        onDetail={(record) => navigate(`/employee/${record.id}`)}
        onEdit={false}
      />
    </HeaderLayout>
  );
}

export default EmployeeList;
