import React from "react";
import ReactDOM from "react-dom/client";
import "antd/dist/antd.variable.min.css";
import "./global/global.scss";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./app/store";
import * as LuigiClient from "@luigi-project/client";
window["LuigiClient"] = LuigiClient;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>
);
