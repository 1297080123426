import { Col } from "antd";
import { schema } from "../../constants";
import FormProfileLeft from "./FormProfileLeft";
import FormProfileRight from "./FormProfileRight";

export default function FormProfile() {
  const yupSync = {
    async validator({ field }, value) {
      await schema.validateSyncAt(field, { [field]: value });
    },
  };
  return (
    <>
      <Col span={12}>
        <FormProfileLeft yupSync={yupSync} />
      </Col>
      <Col span={12}>
        <FormProfileRight yupSync={yupSync} />
      </Col>
    </>
  );
}
