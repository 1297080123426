import { Form, message } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GeneralDrawer from "../../../../components/Drawer/GeneralDrawer";
import { FormInput } from "../../../../components/Form";
import { DEFAULT_STATUS } from "../../components/constants";
import Status from "../../components/Status";
import { backendMessages, names, requiredFields } from "../constants";

export default function ModalForm(props) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { initial, isEditMode } = useSelector((state) => state.drawer);

  const fillData = useCallback(() => {
    form.resetFields();
    const init = isEditMode ? initial : {};
    form.setFieldsValue({
      ...init,
      status: init.status ?? DEFAULT_STATUS,
    });
  }, [form, initial, isEditMode]);

  const catchCallback = useCallback(
    (e) => {
      const msg = e?.response?.data?.message;
      if (msg === backendMessages.position)
        return message.error(t("rank.existed"));
      if (msg?.includes(backendMessages.idx))
        return message.error(t("rank.idxExists"));
    },
    [t]
  );

  return (
    <GeneralDrawer
      {...props}
      form={form}
      fillData={fillData}
      catchCallback={catchCallback}
      requiredFields={requiredFields}
    >
      <FormInput
        name="rank"
        label={t("rank.t")}
        required
        placeholder={t("rank.rankPlaceholder")}
        max={20}
      />
      <FormInput
        name={names.order}
        label={t("position.order")}
        customType="number"
        required
        max={4}
        placeholder={t("rank.indexPlaceholder")}
      />
      <FormInput name="description" label="Description" textArea />
      <Status />
    </GeneralDrawer>
  );
}
