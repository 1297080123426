import { Input } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NumberInput, SalaryInput } from "../CustomBase/";
import FormItem from "./FormItem";
import "./scss/formInput.scss";

export default function FormInput(props) {
	const {
		name,
		required,
		label,
		placeholder,
		min,
		max,
		rows,
		form,
		textArea,
		disabled,
		customType,
		onChange,
		autoSize,
		style,
		className,
		onBlur,
		inputClassName,
		showCount = true,
		hasFeedBack,
		type,
		autoWidth,
		onKeyPress,
		defaultValue,
		value,
		allowClear,
		rules,
	} = props;

	const [inputWidth, setInputWidth] = useState(autoWidth);
	const { t } = useTranslation();

	const lowerLabel = label.toLowerCase();

	const formItemProps = {
		name,
		required,
		label,
		min,
		style,
		className,
		hasFeedBack,
		customType,
		rules,
		form,
	};

	const getInput = () => {
		if (customType === "number") return NumberInput;
		if (customType === "salary") return SalaryInput;
		return textArea ? Input.TextArea : Input;
	};

	const InputComponent = getInput();

	const onChangeInput = (e) => {
		if (onChange) onChange(e);
		if (autoWidth) {
			const nextWidth = e.target.value.length * 9;
			if (nextWidth > autoWidth) {
				setInputWidth(nextWidth);
			}
		}
	};

	const inputProps = {
		placeholder,
		disabled,
		onBlur,
		onKeyPress,
		type,
		defaultValue,
		value,
		allowClear,
		name,
		onChange: onChangeInput,
	};

	inputProps.maxLength = max || 200;
	if (max) inputProps.maxLength = max;
	if (inputWidth) inputProps.style = { width: inputWidth };
	if (inputClassName) inputProps.className = inputClassName;

	if (textArea) {
		if (!placeholder)
			inputProps.placeholder = `${t("common.type")} ${lowerLabel}`;
		inputProps.maxLength = max || 200;
		inputProps.autoSize = autoSize || { minRows: rows || 4 };
		if (showCount) inputProps.showCount = true;
	}

	return (
		<FormItem {...formItemProps} className={className}>
			<InputComponent {...inputProps} />
		</FormItem>
	);
}
