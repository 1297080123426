import { Input } from "antd";
import React, { useEffect } from "react";
import { SPECIAL_KEYS } from "./constants";

export default function NumberInput({ onChange, value, ...props }) {
	const eventClassName = "number-input-" + props.name;

	const onChangeInput = (e) => {
		const str = e.target.value?.trim();
		if (str === "") return onChange("");
		if (str === "0") return;
		const number = +str;
		onChange(number);
	};

	const onKeyPress = (e) => {
		const key = e.key;
		if (!/[0-9]/.test(key) && !SPECIAL_KEYS.includes(key)) {
			e.preventDefault();
		}
	};

	const onKeyDown = (e) => {
		const key = e.key;
		if (!value && key === "0") {
			e.preventDefault();
		}
	};

	useEffect(() => {
		document
			.querySelector("." + eventClassName)
			.addEventListener("keypress", onKeyPress);
	}, [eventClassName]);

	return (
		<Input
			{...props}
			value={value}
			className={eventClassName}
			onChange={onChangeInput}
			onKeyDown={onKeyDown}
		/>
	);
}
