import React, { memo } from "react";
import employeeProjectApi from "../../../api/employeeProjectApi";
import { TableMain, useTable } from "../../../components/Table";
import useGetIdInfoAndEmployee from "../hooks/useGetIdInfoAndEmployee";
import PositionFilter from "./components/ProjectFilter";
import { cols } from "./constants";

export default memo(function EmployeeProject() {
	const { id } = useGetIdInfoAndEmployee();

	const { items, filter, setFilter, loadingTable, fetchData, totalRecord } =
		useTable({
			getApi: employeeProjectApi.getAllStandard(id),
			isPagination: true,
		});

	return (
		<>
			<PositionFilter filter={filter} setFilter={setFilter} />
			<TableMain
				items={items}
				cols={cols}
				filter={filter}
				getApi={employeeProjectApi.getAllStandard(id)}
				Filter={PositionFilter}
				setFilter={setFilter}
				fetchData={fetchData}
				loadingTable={loadingTable}
				totalRecord={totalRecord}
				leftButtons={false}
				onEdit={false}
				idKey={undefined}
				excelName="list-project"
			/>
		</>
	);
});
