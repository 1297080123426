import { Form } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SalaryInput } from "../../../../components/CustomBase";
import GeneralDrawer from "../../../../components/Drawer/GeneralDrawer";
import { FormDatePicker, UserSelect } from "../../../../components/Form";
import { VN_DATE_FORMAT } from "../../../../global/constants";
import { names } from "../constants";
import PositionAndRankSelections from "./PositionAndRankSelections";
import { requiredUpdateCareerFields } from "./constants";

export default function AddCareerPosition({
	isDisabledApprovedBy = false,
	onFinish,
	...props
}) {
	const { initial } = useSelector((state) => state.drawer);
	const [form] = Form.useForm();
	const { t } = useTranslation();

	const transformValues = (values) => {
		const date = values[names.effectiveDate];
		values[names.effectiveDate] = date.format(VN_DATE_FORMAT);
		return values;
	};

	return (
		<GeneralDrawer
			form={form}
			isPagination
			transformValues={transformValues}
			requiredFields={requiredUpdateCareerFields}
			onFinish={onFinish}
			{...props}>
			<PositionAndRankSelections />
			<SalaryInput name={names.newSalary} required />
			<FormDatePicker
				name={names.effectiveDate}
				label={t("employeeCareer.effectiveDate")}
				disabledDate={(date) => {
					return initial?.disabledDate
						? date < moment(initial.disabledDate, VN_DATE_FORMAT)
						: false;
				}}
				format={VN_DATE_FORMAT}
				disabled={initial?.[names.effectiveDate]}
				required
			/>
			<UserSelect
				name={names.approveBy}
				label={t("employeeCareer.approvedBy")}
				disabled={isDisabledApprovedBy}
				required
			/>
		</GeneralDrawer>
	);
}
