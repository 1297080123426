import { Col, Form } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../components/Form";
import RangePicker from "../../../../components/Form/DatePicker/RangePicker";
import { Filter } from "../../../../components/Table";
import { VN_DATE_FORMAT } from "../../../../global/constants";
import { names } from "../constants";
import moment from "moment";

export default function HolidayFilter(props) {
	const [form] = Form.useForm();
	const { t } = useTranslation();

	const submitValues = useCallback((values) => {
		if (values.time) {
			values[names.startDate] = values.time[0].format(VN_DATE_FORMAT);
			values[names.endDate] = values.time[1].format(VN_DATE_FORMAT);
		} else {
			delete values[names.startDate];
			delete values[names.endDate];
		}
		delete values.time;
		return values;
	}, []);

	const initialValues = useCallback((values) => {
		const startDate = values[names.startDate];
		const endDate = values[names.endDate];
		const obj = {};

		if (startDate && endDate) {
			obj.time = [
				moment(startDate, VN_DATE_FORMAT),
				moment(endDate, VN_DATE_FORMAT),
			];
		}

		return { values, ...obj };
	}, []);

	return (
		<Filter
			{...props}
			form={form}
			submitValues={submitValues}
			initialValues={initialValues}>
			<Col span={6}>
				<RangePicker name={names.time} label={t("common.time")} />
			</Col>
			<Col span={4}>
				<FormInput
					name={names.keyword}
					label={t("common.keyword")}
					placeholder={t("common.description")}
				/>
			</Col>
		</Filter>
	);
}
