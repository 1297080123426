export const deepCopy = (obj) => {
	let newObj = JSON.parse(JSON.stringify(obj));
	if (Array.isArray(obj)) {
		obj.forEach((col, i) => {
			const oldFunc = col.render;
			if (oldFunc) {
				newObj[i].render = oldFunc.bind({});
			}
		});
	}
	return newObj;
};
