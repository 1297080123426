import { Tag, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setTableColumns } from "../Slice/tableSlice";
import { SORT_KEY, STATUS_ACTIVE } from "../constants";

import "./scss/useTableCols.scss";

export default function useTableMain({
  cols,
  nth = false,
  titleLabel,
  filter,
  actionCol,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getSortOrder = (col) => {
    const orderby = filter?.[SORT_KEY];
    if (!orderby) return false;
    if (!orderby.includes(col)) return false;
    else {
      if (orderby.includes("ASC")) return "ascend";
      if (orderby.includes("DESC")) return "descend";
    }
  };

  const definedCols = {
    // Begin: General col
    allWithoutSort: {
      show: true,
      export: true,
    },

    all: (dataIndex) => ({
      sortOrder: getSortOrder(dataIndex),
      show: true,
      export: true,
      sorter: true,
    }),
    tooltip: (keyName) => ({
      title: t("typework.description"),
      dataIndex: keyName,
      sortOrder: getSortOrder(keyName),
      width: 500,
      show: true,
      export: true,
      sorter: true,
      render: (value) => (
        <Tooltip title={value} placement="topLeft">
          <div className="text-truncate">{value || ""}</div>
        </Tooltip>
      ),
    }),
    // End: General col
    nth: {
      title: "#",
      show: true,
      export: false,
      dataIndex: "nth",
      align: "center",
      width: 60,
    },
    id: {
      title: "#",
      width: 41,
      sortOrder: getSortOrder("id"),
      dataIndex: nth ? "nth" : "id",
      show: true,
      export: true,
      sorter: true,
    },
    title: (keyName) => ({
      title: titleLabel,
      dataIndex: keyName,
      sortOrder: getSortOrder(keyName),
      width: 200,
      show: true,
      export: true,
      sorter: true,
      render: (value) => <div className="text-truncate">{value || ""}</div>,
    }),
    description: {
      title: t("common.description"),
      dataIndex: "description",
      sortOrder: getSortOrder("description"),
      width: 700,
      show: true,
      export: true,
      sorter: false,
      render: (value) => <div className="text-truncate">{value || ""}</div>,
    },

    status: (keyName) => ({
      title: t("common.status"),
      width: 100,
      dataIndex: keyName,
      align: "center",
      sorter: true,
      render: (value) => {
        if (value === STATUS_ACTIVE) {
          return <Tag color="success">Active</Tag>;
        } else {
          return <Tag color="error">Terminated</Tag>;
        }
      },
      sortOrder: getSortOrder("status"),
      show: true,
      export: true,
    }),
  };

  const getDefaultColumns = () => {
    const getDefinedCol = (type, col) => {
      if (type === "status") {
        if (!col.dataIndex) {
          return definedCols.status("status");
        } else {
          return definedCols.status(col.dataIndex);
        }
      }
      if (type === "all") {
        if (!col.dataIndex) {
          return undefined;
        } else {
          return definedCols.all(col.dataIndex);
        }
      }
      if (type === "tooltip") {
        if (!col.dataIndex) {
          return undefined;
        } else {
          return definedCols.tooltip(col.dataIndex);
        }
      }
      if (type === "title") {
        if (!col.dataIndex) {
          return definedCols.title("title");
        } else {
          return definedCols.title(col.dataIndex);
        }
      }
      if (typeof col.sortOrder === "string") {
        return {
          ...col,
          sortOrder: getSortOrder(col.sortOrder),
        };
      }
      return definedCols[type];
    };

    return cols.map((col, id) => {
      const { type, ...rest } = col;
      const found = getDefinedCol(type, col);
      const defined = type && found ? found : {};
      const other = {};
      if (typeof col.sortOrder === "string") {
        other.sortOrder = getSortOrder(col.sortOrder);
      }
      return { id, colType: type, ...defined, ...rest, ...other };
    });
  };

  useEffect(() => {
    const columns = getDefaultColumns();
    if (actionCol) {
      columns.push(actionCol);
    }
    dispatch(setTableColumns(columns));
    // eslint-disable-next-line
  }, [dispatch, actionCol]);
}
