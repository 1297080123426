import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Form, Row } from "antd";
import React, { memo, useEffect } from "react";
import { DEFAULT_FILTER, SORT_KEY } from "../constants";
import "./style.scss";

export default memo(function Filter({
	form,
	children,
	setFilter,
	filter,
	submit,
	submitValues,
	defaultFilter,
	initialValues = () => undefined,
	hasClean = false,
}) {
	useEffect(() => {
		form.setFieldsValue({
			...filter,
			...initialValues(filter),
		});
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		form.setFieldsValue({
			...filter,
		});
	}, [form, filter]);

	const handleSearch = (values) => {
		const union = { ...filter, ...values };
		if (submitValues) {
			return setFilter(submitValues(union));
		}
		setFilter(union);
	};

	return (
		<div className="table-filter">
			<Form form={form} onFinish={submit || handleSearch} layout="vertical">
				<Row className="field-row" gutter={[10, 10]}>
					{children}
					{hasClean && (
						<div
							className="cleaner"
							onClick={() => {
								form.resetFields();
								const newFilter = {
									...DEFAULT_FILTER,
									...defaultFilter,
									[SORT_KEY]: filter[SORT_KEY],
								};
								if (filter[SORT_KEY]) newFilter[SORT_KEY] = filter[SORT_KEY];
								setFilter(newFilter);
							}}>
							<ClearOutlined
								style={{
									cursor: "pointer",
									fontSize: 16,
									margin: "auto",
								}}
							/>
						</div>
					)}
					<Button
						type="primary"
						htmlType="submit"
						icon={<SearchOutlined />}></Button>
				</Row>
			</Form>
		</div>
	);
});
