import React from "react";
import { useTranslation } from "react-i18next";
import rankApi from "../../../api/rankApi";
import { HeaderLayout } from "../../../components/Layouts";
import { TableMain, useTable } from "../../../components/Table";
import ModalForm from "./components/ModalForm";
import { cols } from "./constants";

export default function Rank() {
  const { t } = useTranslation();

  const { items, filter, setFilter, loadingTable, fetchData, totalRecord } =
    useTable({
      getApi: rankApi.getAll,
      isPagination: true,
    });

  return (
    <HeaderLayout title={t("rank.mainTitle")}>
      <ModalForm
        fetchData={fetchData}
        addApi={rankApi.add}
        editApi={rankApi.edit}
        isPagination={true}
        setFilter={setFilter}
      />
      <TableMain
        items={items}
        cols={cols}
        filter={filter}
        setFilter={setFilter}
        fetchData={fetchData}
        getApi={rankApi.getAll}
        deleteManyApi={rankApi.deleteMany}
        loadingTable={loadingTable}
        deleteContentKey="rank"
        title={t("rank.t")}
        totalRecord={totalRecord}
        excelName="list-rank"
      />
    </HeaderLayout>
  );
}
