import { InputNumber } from "antd";
import React from "react";
import { SPECIAL_KEYS } from "../NumberInput/constants";
import { formatSalary } from "../../../utils/form";
import { useTranslation } from "react-i18next";

function SalaryInputCore({ onChange, value, onKeyDown, ...props }) {
	const { t } = useTranslation();
	return (
		<InputNumber
			placeholder={t("form.salaryPlaceholder")}
			onChange={(value) => {
				onChange(formatSalary(value));
			}}
			onKeyDown={(e) => {
				if (!(SPECIAL_KEYS.includes(e.key) || /[\d]/g.test(e.key))) {
					e.preventDefault();
				}
				onKeyDown?.(e);
			}}
			formatter={formatSalary}
			parser={(value) => value?.replace(/\$\s?|(\.*)/g, "")}
			value={value}
			className="w-full"
			{...props}
		/>
	);
}

export default SalaryInputCore;
