import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, message } from "antd";
import i18n from "../translation/i18n";
import { getPopupContainer } from "./common";

export const confirmDelete = ({ handleDelete, ...props } = {}) => {
	Modal.confirm({
		title: (
			<div className="language__multipleDelete--title">
				{i18n.t("table.confirmDelete")}
			</div>
		),
		icon: <ExclamationCircleOutlined />,
		okText: i18n.t("common.yes"),
		cancelText: i18n.t("common.no"),
		onOk: async () => {
			try {
				await handleDelete?.();
				message.success(i18n.t("table.deleteSuccessfully"));
			} catch (error) {
				console.log("error :", error);
				message.error(i18n.t("message.deleteFailed"));
			}
		},
		getContainer: getPopupContainer,
		...props,
	});
};
