import positionApi from "../../../api/positionApi";
import rankApi from "../../../api/rankApi";
import departmentApi from "../../../api/departmentApi";
import employeeApi from "../../../api/employeeApi";
import GeneralSelect from "./GeneralSelect";
import { useTranslation } from "react-i18next";

export function PositionSelect(props) {
	const { t } = useTranslation();
	return (
		<GeneralSelect
			contentKey="position"
			label={t("position.t")}
			api={positionApi.getAll}
			{...props}
		/>
	);
}

export function RankSelect(props) {
	const { t } = useTranslation();
	return (
		<GeneralSelect
			label={t("rank.t")}
			contentKey="rank"
			api={rankApi.getAll}
			{...props}
		/>
	);
}

export function DepartmentSelect(props) {
	const { t } = useTranslation();
	return (
		<GeneralSelect
			contentKey="department"
			label={t("department.t")}
			api={departmentApi.getAll}
			{...props}
		/>
	);
}

export function UserSelect(props) {
	return <GeneralSelect contentKey="id" api={employeeApi.getAll} {...props} />;
}
