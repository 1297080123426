import queryString from "query-string";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setTableFilter } from "../../../app/common";
import { DEFAULT_FILTER } from "../constants";

export default function useTable({
	getApi,
	isPagination,
	resCallback = (res) => res.data.data,
}) {
	const [items, setItems] = useState();
	const [loadingTable, setLoadingTable] = useState(false);
	const [totalRecord, setTotalRecord] = useState(0);
	const [reloadTable, setReloadTable] = useState(false);

	const location = useLocation();
	const dispatch = useDispatch();

	const [filter, setFilter] = useState(() => {
		const searchParam = queryString.parse(location.search, {
			parseNumbers: true,
		});

		const filterParam = { ...searchParam };
		delete filterParam.tab;

		const defaultParams = isPagination ? DEFAULT_FILTER : {};

		return Object.keys(filterParam).length !== 0 ? filterParam : defaultParams;
	});

	const calculateNth = (index) => {
		const curPage = filter.currentPage ?? DEFAULT_FILTER.currentPage;
		const numberPerPage = filter.limit ?? DEFAULT_FILTER.limit;
		return (curPage - 1) * numberPerPage + index + 1;
	};

	const fetchDataForTable = () => {
		setLoadingTable(true);
		const newFilter = {};
		const oriFilter = JSON.parse(JSON.stringify(filter));
		for (let key in oriFilter) {
			let value = oriFilter[key];
			if (typeof value === "string") {
				value = value.trim();
			}
			newFilter[key] = value;
		}

		getApi(newFilter)
			.then((res) => {
				const arr = resCallback(res);
				const total = res.data.totalElements || arr.length;
				const items = arr?.map((item, index) => ({
					nth: calculateNth(index, total),
					...item,
				}));
				setTotalRecord(total);
				setItems(items);

				setLoadingTable(false);
				if (
					res.data.totalPages &&
					res.data.totalPages < newFilter.currentPage
				) {
					setFilter({ ...newFilter, currentPage: res.data.totalPages });
				}
			})
			.catch((e) => {
				console.log(e);
				setLoadingTable(false);
			});
	};

	const fetchData = () => {
		setReloadTable((pre) => !pre);
	};

	useEffect(() => {
		fetchDataForTable();
		dispatch(setTableFilter(filter));
		return () => {
			dispatch(setTableFilter({}));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter, reloadTable, dispatch]);

	return {
		items,
		filter,
		setFilter,
		loadingTable,
		fetchData,
		totalRecord,
	};
}
