import React from "react";
import { useTranslation } from "react-i18next";
import departmentApi from "../../../api/departmentApi";
import { HeaderLayout } from "../../../components/Layouts";
import { TableMain, useTable } from "../../../components/Table";
import Filter from "./components/Filter";
import ModalForm from "./components/ModalForm";
import { cols, names } from "./constants";

export default function Department() {
	const { t } = useTranslation();

	const { items, filter, setFilter, loadingTable, fetchData, totalRecord } =
		useTable({
			getApi: departmentApi.getAll,
			isPagination: true,
		});

	return (
		<HeaderLayout title={t("department.mainTitle")}>
			<Filter filter={filter} setFilter={setFilter} />
			<ModalForm
				fetchData={fetchData}
				addApi={departmentApi.add}
				editApi={departmentApi.edit}
				setFilter={setFilter}
			/>
			<TableMain
				items={items}
				cols={cols}
				filter={filter}
				setFilter={setFilter}
				fetchData={fetchData}
				getApi={departmentApi.getAll}
				Filter={Filter}
				deleteManyApi={departmentApi.deleteMany}
				loadingTable={loadingTable}
				deleteContentKey={names.department}
				totalRecord={totalRecord}
				excelName="list-department"
				title={t("department.department")}
			/>
		</HeaderLayout>
	);
}
