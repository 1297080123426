import { Card, Col, Select } from "antd";
import React from "react";

import { useTranslation } from "react-i18next";

import { MAX_INPUT_LEN } from "../../../../../constants/rules";
import { FORMAT_DATE } from "../../constants";
import OptionsField from "../OptionsField";

export default function FormProfileLeft({ yupSync }) {
  const { t } = useTranslation();
  return (
    <div className="profile_content-personal">
      <Card
        style={{ background: "#FAFAFA", marginBottom: "24px" }}
        bordered={false}
      >
        <h2>{t("employees.personalInfo")}</h2>
      </Card>
      <OptionsField
        label={t("employees.fullName")}
        name="fullName"
        disabled={true}
        type="input"
      />
      <OptionsField
        label={t("employees.viFullName")}
        name="viFullName"
        type="input"
      />
      <OptionsField
        label={t("employees.DateOfBirth")}
        name="dateOfBirth"
        type="datePicker"
        formatDisplay={(value) => value?.format?.(FORMAT_DATE)}
        disabledYear={(value) => {
          const now = new Date();
          return now.getFullYear() - 15 < value.year();
        }}
      />
      <OptionsField
        label={t("employees.identityCard")}
        name="identityCard"
        type="input"
        rules={[yupSync]}
      />
      <OptionsField
        label={t("employees.DateOfIssue")}
        name="dateOfIssue"
        type="datePicker"
        formatDisplay={(value) => value?.format?.(FORMAT_DATE)}
        disabledYear={(value) => {
          return value.valueOf() > Date.now();
        }}
      />
      <OptionsField
        label={t("employees.PlaceOfIssue")}
        name="placeOfIssue"
        type="input"
        maxLength={MAX_INPUT_LEN}
      />
      <OptionsField
        label={t("employees.PlaceOfResidence")}
        name="placeOfResidence"
        type="input"
        maxLength={MAX_INPUT_LEN}
      />
      <OptionsField
        label={t("employees.PlaceOfsojourn")}
        name="placeOfSojourn"
        type="input"
        maxLength={MAX_INPUT_LEN}
      />
      <OptionsField
        label={t("employees.bank")}
        name="bank"
        type="input"
        maxLength={MAX_INPUT_LEN}
      />
      <OptionsField
        label={t("employees.bankAccount")}
        name="bankAccount"
        type="input"
        rules={[yupSync]}
      />
      <OptionsField
        label={t("employees.bankBranch")}
        name="bankBranch"
        type="input"
        maxLength={MAX_INPUT_LEN}
      />
    </div>
  );
}
