import React from "react";
import { useTranslation } from "react-i18next";
import { FormItem } from "../../Form";
import SalaryInputCore from "./SalaryInputCore";

export default function SalaryInput({
	onKeyDown,
	name,
	required,
	label,
	min,
	formItemProps,
	rules,
	noStyle,
	shouldUpdate,
	...props
}) {
	const { t } = useTranslation();

	return (
		<FormItem
			label={label ?? t("common.salary")}
			name={name}
			noStyle={noStyle}
			shouldUpdate={shouldUpdate}
			min={min}
			rules={rules}
			required={required}
			customType="number"
			{...formItemProps}>
			<SalaryInputCore {...props} />
		</FormItem>
	);
}
