import { Card, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import contractTypeApi from "../../../../../api/contractTypeApi";
import memberTypeApi from "../../../../../api/memberTypeApi";
import { MAX_INPUT_LEN_50 } from "../../../../../constants/rules";
import OptionsField from "../OptionsField";
import { FORMAT_DATE } from "../../constants";
const { Option } = Select;

function FormProfileRight({ yupSync }) {
  const { t } = useTranslation();
  const [memberType, setMemberType] = useState();
  const [contactType, setContactType] = useState();
  useEffect(() => {
    (async () => {
      try {
        const { data } = await memberTypeApi.getAll();
        setMemberType(data?.data);
        const { data: dt } = await contractTypeApi.getAll();
        setContactType(dt?.data);
      } catch (error) {}
    })();
  }, []);
  return (
    <div className="profile_content-job">
      <Card
        style={{ background: "#FAFAFA", marginBottom: "24px" }}
        bordered={false}
      >
        <h2>{t("employees.jobInfo")}</h2>
      </Card>
      <OptionsField
        label={t("employees.id")}
        name="id"
        type="input"
        disabled={true}
      />
      <OptionsField
        label={t("employees.alias")}
        name="alias"
        type="input"
        maxLength={MAX_INPUT_LEN_50}
      />
      <OptionsField
        label={t("employees.jobEmail")}
        name="emailCompany"
        type="input"
        mode="tags"
        disabled={true}
      />
      <OptionsField
        label={t("employees.personalEmail")}
        name="emailPersonal"
        type="input"
        mode="tags"
        rules={[yupSync]}
      />
      <OptionsField
        label={t("employees.phoneNumber")}
        name="phoneNumber"
        type="input"
        rules={[yupSync]}
      />
      <OptionsField
        label={t("employees.onBoard")}
        name="onBoard"
        type="datePicker"
        formatDisplay={(value) => value?.format?.(FORMAT_DATE)}
      />

      <OptionsField
        label={t("position.t")}
        name="position"
        type="input"
        disabled={true}
      />
      <OptionsField
        label={t("rank.t")}
        name="rank"
        disabled={true}
        type="input"
      />
      <OptionsField
        label={t("employees.potentialPosition")}
        name="potentialPosition"
        type="select"
        mode="tags"
        disabled={true}
      />

      <OptionsField
        label={t("employees.ContractType")}
        name={["contractType"]}
        type="select"
        children={contactType?.map((contract) => {
          return (
            <Option value={contract.id} key={contract.id}>
              {contract.contractType}
            </Option>
          );
        })}
      />
      <OptionsField
        label={t("employees.MemberType")}
        name={["memberType"]}
        type="select"
        children={memberType?.map((member) => {
          return (
            <Option value={member.id} key={member.id}>
              {member.memberType}
            </Option>
          );
        })}
      />
    </div>
  );
}

export default FormProfileRight;
