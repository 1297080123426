import { CrownOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import employeeCareerApi from "../../../api/employeeCareerApi";
import { openDrawer, setLocale } from "../../../components/Drawer/slice/drawer";
import { TableMain, useTable } from "../../../components/Table";
import useGetIdInfoAndEmployee from "../hooks/useGetIdInfoAndEmployee";
import { cols, names } from "./constants";
import AddCareerPosition from "./form/Add";
import { VN_DATE_FORMAT } from "../../../global/constants";
import moment from "moment";

export default function EmployeeCareer() {
	const { id, isInfo } = useGetIdInfoAndEmployee();

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const transformColsForExport = useCallback((cols) => {
		return cols.map((col) => {
			if (col.dataIndex === names.position) {
				col.render = (value, row) => {
					return `${value}${row[names.isCritical] ? " - Critical" : ""}`;
				};
			}
			return col;
		});
	}, []);

	const { items, filter, setFilter, loadingTable, fetchData, totalRecord } =
		useTable({
			getApi: employeeCareerApi.getAllStandard(id),
			isPagination: true,
			resCallback: (res) => res.data.page.data,
		});

	const openEdit = useCallback(async () => {
		let onboardDate;
		let disabledDate;
		try {
			const resCareer = await employeeCareerApi
				.getAllStandard(id)({ limit: 2 })
				.then(({ data }) => data);
			const records = resCareer?.page?.data;
			if (records.length > 0) {
				const currentCareer = records.find(
					(record) =>
						moment(record.startDate, VN_DATE_FORMAT).startOf("day") < moment()
				);
				if (currentCareer) {
					disabledDate = currentCareer.startDate;
				} else {
					disabledDate = records[records.length - 1].startDate;
				}
			} else {
				onboardDate = resCareer?.onboard;
			}
		} catch (error) {
			console.log("error :", error);
			message.error(error?.message);
		}
		dispatch(
			openDrawer({
				disabledDate,
				[names.effectiveDate]: onboardDate
					? moment(onboardDate, VN_DATE_FORMAT)
					: undefined,
			})
		);
		dispatch(
			setLocale({
				btn: t("common.save"),
				title: t("employeeCareer.newCareer"),
			})
		);
	}, [t, dispatch, id]);

	return (
		<>
			<AddCareerPosition
				onFinish={employeeCareerApi.addAndEdit(id)}
				fetchData={fetchData}
				setFilter={setFilter}
			/>
			<TableMain
				items={items}
				cols={cols}
				filter={filter}
				getApi={employeeCareerApi.getAllStandard(id)}
				setFilter={setFilter}
				fetchData={fetchData}
				transformColsForExport={transformColsForExport}
				leftButtons={
					isInfo ? (
						false
					) : (
						<>
							<Button
								type="primary"
								icon={<CrownOutlined />}
								onClick={openEdit}>
								{t("employeeCareer.newCareer")}
							</Button>
						</>
					)
				}
				onEdit={false}
				loadingTable={loadingTable}
				totalRecord={totalRecord}
				excelName="list-career"
			/>
		</>
	);
}
