import { Routes, Route, Navigate } from "react-router-dom";

import Position from "./pages/Setting/Position";
import Rank from "./pages/Setting/Rank";
import MemberType from "./pages/Setting/MemberType";
import ContractType from "./pages/Setting/ContractType";
import ConvertedRate from "./pages/Setting/ConvertedRate";
import EmployeeList from "./pages/Employee/EmployeeList";
import EmployeeLayout from "./pages/Employee/Layout";
import MyInfoLayout from "./pages/MyInfo/Layout";
import useListenChangeParams from "./global/useListenChangeParams";
import Holiday from "./pages/Setting/Holiday";
import Department from "./pages/Setting/Department";

function App() {
	useListenChangeParams();
	return (
		<>
			<Routes>
				<Route path="employee">
					<Route index element={<Navigate to="list" />} />
					<Route path="list" element={<EmployeeList />} />
					<Route path=":id" element={<EmployeeLayout />}></Route>
				</Route>
				<Route path="my-info" element={<MyInfoLayout />} />
				<Route path="setting">
					<Route path="position" element={<Position />} />
					<Route path="rank" element={<Rank />} />
					<Route path="holiday" element={<Holiday />} />
					<Route path="department" element={<Department />} />
					<Route path="rate" element={<ConvertedRate />} />
					<Route path="member-type" element={<MemberType />} />
					<Route path="contract-type" element={<ContractType />} />
				</Route>
			</Routes>
		</>
	);
}

export default App;
