import { Card, Form, message, Row } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import employeeApi from "../../../../api/employeeApi";
import { setVisible } from "../../../../app/common";
import {
  CancelWhiteButton,
  EditBlueButton,
  SaveButton,
} from "../../../../components/Buttons";
import FormProfile from "../components/formProfile";
import moment from "moment";
import "./showProfile.scss";
import { disabledFields, FORMAT_DATE, INVALID_DATEPICKER } from "../constants";
import UploadAvatar from "../components/UploadAvatar";
import { useCallback } from "react";
import { useState } from "react";
import { forIn } from "lodash";
import { keys } from "../constants";

export default function ShowProfile({ id }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { visible } = useSelector((state) => state.common);
  const [dataInfo, setDataInfo] = useState();
  useEffect(() => {
    const getDataInfo = async () => {
      try {
        const { data } = await employeeApi.getById(id);
        setDataInfo(data.data);
        if (data?.data) {
          const fields = [];
          for (const key of keys) {
            fields.push({
              name: key,
              value: data.data[key],
            });
          }
          fields.push(
            {
              name: "dateOfBirth",
              value: data.data.dateOfBirth
                ? moment(data.data.dateOfBirth, FORMAT_DATE)
                : undefined,
            },
            {
              name: "dateOfIssue",
              value: data.data.dateOfIssue
                ? moment(data.data.dateOfIssue, FORMAT_DATE)
                : undefined,
            },
            {
              name: "onBoard",
              value: data.data.onBoard
                ? moment(data.data.onBoard, FORMAT_DATE)
                : undefined,
            }
          );
          if (visible) {
            fields.push(
              {
                name: "contractType",
                value: data?.data.contractTypeId,
              },
              {
                name: "memberType",
                value: data?.data.memberTypeId,
              }
            );
          }
          form.setFields(fields);
        }
      } catch (error) {}
    };

    getDataInfo();
  }, [visible, id, form]);

  const handleSubmit = useCallback(
    async (values) => {
      const {
        dateOfIssue,
        dateOfBirth,
        memberType,
        contractType,
        uploadAvatar,
        onBoard,
        ...restValues
      } = values;

      const newValues = Object.assign(
        {
          memberTypeId: memberType,
          contractTypeId: contractType,
          dateOfIssue: moment(dateOfIssue, FORMAT_DATE).format(FORMAT_DATE),
          dateOfBirth: moment(dateOfBirth, FORMAT_DATE).format(FORMAT_DATE),
          onBoard: moment(onBoard, FORMAT_DATE).format(FORMAT_DATE),
          uploadAvatar: undefined,
        },
        restValues
      );
      disabledFields.forEach((disabledField) => {
        delete newValues[disabledField];
      });
      forIn(newValues, (value, key) => {
        if (
          value === undefined ||
          value === null ||
          value === INVALID_DATEPICKER
        ) {
          delete newValues[key];
        }
      });
      if (uploadAvatar) {
        const { data } = await employeeApi.editAvatar(
          id,
          uploadAvatar?.originFileObj
        );
        setDataInfo({ ...dataInfo, avatarUri: data });
      }

      await employeeApi.edit(id, newValues);
      dispatch(setVisible(false));
      message.success(t("message.success"));
    },
    [visible, form]
  );
  return (
    <Card>
      {visible ? (
        <div className="group_buttons">
          <Form.Item noStyle shouldUpdate>
            <SaveButton
              htmlType="submit"
              type="primary"
              onClick={() => {
                form.submit();
              }}
            />
          </Form.Item>
          <Form.Item>
            <CancelWhiteButton
              children={t("common.cancel")}
              onClick={() => {
                dispatch(setVisible(false));
              }}
            />
          </Form.Item>
        </div>
      ) : (
        <div className={`button_Edit-${visible}`}>
          <EditBlueButton
            children={t("common.edit")}
            onClick={() => dispatch(setVisible(true))}
          />
        </div>
      )}

      <div className="profile">
        <div className="profile_content">
          <Form
            form={form}
            labelAlign="right"
            labelCol={{ span: 4 }}
            wrapperCol={{ offset: 1 }}
            colon={false}
            onFinish={handleSubmit}
            key={id}
          >
            <UploadAvatar dataInfo={dataInfo} />
            <Row>
              <FormProfile />
            </Row>
          </Form>
        </div>
      </div>
    </Card>
  );
}
