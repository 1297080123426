import React, { useEffect } from "react";
import { HeaderLayout } from "../../../components/Layouts";
import holidayApi from "../../../api/holidayApi";
import { TableMain, useTable } from "../../../components/Table";
import { cols, names } from "./constants";
import { useTranslation } from "react-i18next";
import ModalForm from "./components/ModalForm";
import Filter from "./components/Filter";

export default function Holiday() {
	const { t } = useTranslation();

	const { items, filter, setFilter, loadingTable, fetchData, totalRecord } =
		useTable({
			getApi: holidayApi.getAll,
			isPagination: true,
		});

	return (
		<HeaderLayout title={t("holiday.mainTitle")}>
			<Filter filter={filter} setFilter={setFilter} />
			<ModalForm
				fetchData={fetchData}
				addApi={holidayApi.add}
				editApi={holidayApi.edit}
				setFilter={setFilter}
			/>
			<TableMain
				items={items}
				cols={cols}
				filter={filter}
				setFilter={setFilter}
				fetchData={fetchData}
				getApi={holidayApi.getAll}
				Filter={Filter}
				deleteManyApi={holidayApi.deleteMany}
				loadingTable={loadingTable}
				deleteContentKey={names.date}
				totalRecord={totalRecord}
				addBtnLabel={t("common.add")}
				excelName="list-holiday"
				title={t("holiday.holiday")}
			/>
		</HeaderLayout>
	);
}
