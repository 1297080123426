import { message } from "antd";
import axios from "axios";
import queryString from "query-string";
import i18n from "../translation/i18n";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: "http://192.168.1.68:8080/api", //Khanh
  //   baseURL: "http://192.168.1.51:8080", //Tien
  headers: {
    "content-type": "application/json",
  },
  paramsSerializer: (params) =>
    queryString.stringify(params, { skipNull: true, skipEmptyString: true }),
});

axiosClient.interceptors.response.use(
  function (response) {
    if (response.status === 200) {
      return response;
    }
    return Promise.reject(response);
  },
  function (res) {
    if (!res?.response?.status) message.error(i18n.t("common.network"));
    return Promise.reject(res);
  }
);

export default axiosClient;
