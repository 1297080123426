import React from "react";
import useGetIdInfoAndEmployee from "../hooks/useGetIdInfoAndEmployee";
import ShowFrofile from "./ShowProfile";

export default function EmployeeGeneral() {
  const { id } = useGetIdInfoAndEmployee();

  return (
    <div>
      <ShowFrofile id={id} key={id} />
    </div>
  );
}
