import React from "react";
import { HeaderLayout } from "../../../components/Layouts/";
import positionApi from "../../../api/positionApi";
import { TableMain, useTable } from "../../../components/Table";
import Filter from "./components/Filter";
import { cols, names } from "./constants";
import { useTranslation } from "react-i18next";
import ModalForm from "./components/ModalForm";

export default function Position() {
	const { t } = useTranslation();

	const { items, filter, setFilter, loadingTable, fetchData, totalRecord } =
		useTable({
			getApi: positionApi.getAll,
			isPagination: true,
		});

	return (
		<HeaderLayout title={t("position.mainTitle")}>
			<Filter filter={filter} setFilter={setFilter} />
			<ModalForm
				fetchData={fetchData}
				addApi={positionApi.add}
				editApi={positionApi.edit}
				isPagination
				setFilter={setFilter}
			/>
			<TableMain
				items={items}
				cols={cols}
				filter={filter}
				Filter={Filter}
				setFilter={setFilter}
				fetchData={fetchData}
				getApi={positionApi.getAll}
				deleteManyApi={positionApi.deleteMany}
				loadingTable={loadingTable}
				deleteContentKey={names.position}
				totalRecord={totalRecord}
				excelName="list-position"
				title={t("position.t")}
			/>
		</HeaderLayout>
	);
}
