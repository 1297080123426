import { Form, Input, message } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GeneralDrawer from "../../../../components/Drawer/GeneralDrawer";
import { FormInput } from "../../../../components/Form";
import { DEFAULT_STATUS } from "../../components/constants";
import Status from "../../components/Status";
import { backendMessages, requiredFields } from "../constants";

export default function ModalForm(props) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { initial, isEditMode } = useSelector((state) => state.drawer);

  const fillData = useCallback(() => {
    const init = isEditMode ? initial : {};
    form.setFieldsValue({
      ...init,
      status: init.status ?? DEFAULT_STATUS,
      workTimes: init.workTimes ?? 0,
    });
  }, [form, isEditMode, initial]);

  const catchCallback = useCallback(
    (e) => {
      const msg = e?.response?.data?.message;
      if (msg === backendMessages.contractType)
        return message.error(t("contractType.contractTypeExists"));
    },
    [t]
  );
  const handleKeyDown = (event) => {
    // Lấy mã ASCII của phím người dùng đã nhấn
    const keyCode = event.keyCode || event.which;
    // Kiểm tra nếu mã ASCII thuộc phạm vi của các ký tự chữ
    if ((keyCode >= 65 && keyCode <= 90) || (keyCode >= 97 && keyCode <= 122)) {
      event.preventDefault();
    }
  };

  return (
    <GeneralDrawer
      {...props}
      form={form}
      fillData={fillData}
      catchCallback={catchCallback}
      requiredFields={requiredFields}
    >
      {" "}
      <FormInput
        name="contractType"
        label={t("contractType.contractType")}
        required
        placeholder={t("contractType.contractTypePlaceholder")}
        max={20}
      />
      <Form.Item name="workTimes" label={t("contractType.workTimes")}>
        <Input
          type="number"
          onKeyDown={handleKeyDown}
          min={0}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <FormInput name="description" label="Description" textArea />
      <Status />
    </GeneralDrawer>
  );
}
