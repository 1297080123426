import { StarFilled } from "@ant-design/icons";
import i18n from "../../../translation/i18n";

export const names = {
	position: "position",
	positionId: "positionId",
	newPosition: "newPosition",
	rank: "rank",
	rankId: "rankId",
	newRank: "newRank",
	salary: "salary",
	newSalary: "newSalary",
	startDate: "startDate",
	endDate: "endDate",
	approveBy: "approveBy",
	isCritical: "isDefault",
	effectiveDate: "effectiveDate",
};

export const cols = [
	{ type: "nth" },
	{
		type: "allWithoutSort",
		title: i18n.t("position.t"),
		dataIndex: names.position,
		width: "20%",
		render: (value, row) => {
			return (
				<>
					{value}
					{row[names.isCritical] ? (
						<span
							style={{
								marginLeft: 7,
								color: "#FADB14",
							}}>
							<StarFilled />
						</span>
					) : (
						""
					)}
				</>
			);
		},
	},
	{
		type: "allWithoutSort",
		title: i18n.t("rank.t"),
		dataIndex: names.rank,
		width: "15%",
	},
	{
		type: "allWithoutSort",
		title: i18n.t("common.salary"),
		dataIndex: names.salary,
		width: "12%",
	},
	{
		type: "allWithoutSort",
		title: i18n.t("employeeCareer.approvedBy"),
		dataIndex: names.approveBy,
		width: "12%",
	},
	{
		type: "all",
		title: i18n.t("common.startDate"),
		dataIndex: names.startDate,
	},
	{
		type: "allWithoutSort",
		title: i18n.t("common.endDate"),
		dataIndex: names.endDate,
	},
];
