import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Card, Collapse, Form, message } from "antd";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import convertedRateApi from "../../../api/convertRateApi";
import { SaveBlueButton } from "../../../components/Buttons";
import HeaderLayout from "../../../components/Layouts/Header";
import ChildrenTable from "./components/ChildrenTable";
import "./convertRate.scss";
const { Panel } = Collapse;
message.config({
	getContainer: document.querySelector(".convert_header"),
});

export default function ConvertedRate() {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [positions, setPosition] = useState();
	useEffect(() => {
		(async () => {
			try {
				const { data } = await convertedRateApi.getAll();
				setPosition(data);
			} catch (error) {}
		})();
	}, []);

	const onFinish = useCallback(
		async (values) => {
			const listData = [];
			for (const key in values) {
				listData.push({
					id: key,
					value: values[key],
				});
			}
			try {
				await convertedRateApi.edit({
					requests: listData,
				});
				message.success(t("message.success"));
			} catch (e) {
				console.log("error :", e);
				message.error(e?.response?.data?.message ?? e.message);
			}
		},
		[t]
	);

	return (
		<Form form={form} onFinish={onFinish}>
			<HeaderLayout
				title={t("convertedRates.rateTitle")}
				className="convert_header"
				style={{ backgroundColor: "#fff" }}
				button={
					<Form.Item noStyle shouldUpdate>
						{({ isFieldsTouched, getFieldsError }) => (
							<SaveBlueButton
								color="danger"
								className="editRate"
								children={t("common.save")}
								htmlType="submit"
								disabled={
									!isFieldsTouched() ||
									getFieldsError().filter(({ errors }) => errors.length)
										.length > 0
								}
							/>
						)}
					</Form.Item>
				}>
				<Card style={{ margin: "21px 16px" }} bordered={false}>
					<div className="table_Rates">
						<Collapse
							bordered={false}
							expandIcon={({ isActive }) =>
								isActive ? <UpOutlined /> : <DownOutlined />
							}
							style={{ width: "75%" }}>
							{positions?.map((pos, i) => (
								<Panel header={pos.position} key={i}>
									<ChildrenTable dataSource={pos} id={pos.id} />
								</Panel>
							))}
						</Collapse>
					</div>
				</Card>
			</HeaderLayout>
		</Form>
	);
}
