export const SPECIAL_KEYS = [
	"Alt",
	"Tab",
	"Control",
	"Shift",
	"Backspace",
	"Enter",
	"ArrowLeft",
	"ArrowUp",
	"ArrowRight",
	"ArrowDown",
	"Meta",
	"Delete",
];
