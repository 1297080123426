import { deepCopy } from "../../utils/object";

export const PAGE_SIZE_OPTIONS = ["10", "20", "50"];
export const DEFAULT_FILTER = { currentPage: 1, limit: 10 };
export const CONFIG_PAGINATION = {
  showQuickJumper: false,
  showSizeChanger: true,
  defaultCurrent: 1,
  pageSize: DEFAULT_FILTER.limit,
  total: 0,
  pageSizeOptions: PAGE_SIZE_OPTIONS,
};
export const SORT_KEY = "sort";
export const DEFAULT_STYLE_EXCEL = {
  fontName: "Times New Roman",
  h: "left",
  v: "left",
};
export const STATUS_ACTIVE = 1;

export const DEFAULT_LIMIT_RECORDS_EXPORT = 500;

export const onChangeTable = (
  filter,
  setFilter,
  columns,
  setColumns,
  setSelects,
  nth = false,
  nthBy = "id"
) => {
  return function (e, filterParam, sorter) {
    setSelects([]);
    const colName = nth && sorter.field === "nth" ? nthBy : sorter.field;
    const orderValue = sorter.order;
    const newColumns = deepCopy(columns);
    newColumns.forEach((col) => {
      if (col.dataIndex === colName) {
        col.sortOrder = orderValue;
      } else {
        col.sortOrder = false;
      }
      return col;
    });
    setColumns(newColumns);
    const sorterValue = orderValue
      ? `${colName}-${orderValue === "ascend" ? "ASC" : "DESC"}`
      : "";
    const newFilterParam = {};
    for (let key in filterParam) {
      newFilterParam[key] = filterParam[key] ? filterParam[key].join("-") : "";
    }
    setFilter({
      ...filter,
      currentPage: e.current,
      limit: e.pageSize,
      [SORT_KEY]: sorterValue,
      ...newFilterParam,
    });
  };
};
