// Begin: handle salary
export const formatSalary = (value) =>
	value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".") : value;

// End: handle salary
export const convertInputCellValidate = (value) => {
	const dotIndex = value.indexOf(".");
	if (dotIndex > -1) {
		const secondDot = value.indexOf(".", dotIndex + 1);
		if (secondDot > -1) {
			return value.slice(0, secondDot);
		}
		const temp = value.substring(dotIndex + 1, value.length);
		if (temp.length > 4) return value.slice(0, value.length - 1);
	}
	return value.replace(/[^\d.]/, "");
};
// validation input cell.
