import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { isFunction } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CreateButton } from "../../../../Buttons";
import ToggleVisibleCols from "./ToggleVisibleCols";
import TooltipBtn, { FullScreenButton } from "./TooltipBtn";
import { exportToExcel, transformCols } from "./exportToExcel";

const UtilitiesBars = ({
	handleFullScreen,
	transformColsForExport,
	filter,
	addBtnLabel,
	buttons = ["export", "toggle", "fullscreen"],
	leftButtons,
	totalRecord,
	excelName,
	getApi,
	onCreate,
	onDelete,
}) => {
	const { t } = useTranslation();

	const { columns } = useSelector((state) => state.table);

	const handleExport = useCallback(() => {
		const newColumns = transformCols(columns, transformColsForExport);
		exportToExcel({
			excelName,
			columns: newColumns,
			totalRecords: totalRecord,
			listParams: filter,
			fetchApi: getApi,
		});
		// eslint-disable-next-line
	}, [excelName, columns, totalRecord, filter, getApi]);

	const definedButtons = useMemo(
		() => ({
			export: (
				<TooltipBtn
					title={t("table.export")}
					onClick={handleExport}
					icon={<DownloadOutlined className="icon-md" />}
				/>
			),
			toggle: <ToggleVisibleCols />,
			fullscreen: <FullScreenButton handleFullScreen={handleFullScreen} />,
		}),
		[t, handleFullScreen, handleExport]
	);

	return (
		<div className="action">
			<div className="action-all">
				{leftButtons ?? (
					<>
						{isFunction(onCreate) && (
							<CreateButton onClick={onCreate} children={addBtnLabel} />
						)}
						{isFunction(onDelete) && (
							<Button
								danger
								icon={<DeleteOutlined />}
								disabled={!isFunction(onDelete)}
								onClick={onDelete}>
								{t("common.delete")}
							</Button>
						)}
					</>
				)}
			</div>
			<div
				className="action-btn"
				style={{ textAlign: "end", flex: 1, justifyContent: "flex-end" }}>
				<div style={{ display: "inline-flex", gap: 6 }}>
					{buttons.map((button, i) => {
						if (typeof button === "string") {
							const defined = definedButtons[button];
							if (defined)
								return <React.Fragment key={i}>{defined}</React.Fragment>;
							return null;
						}
						return <React.Fragment key={i}>{button}</React.Fragment>;
					})}
				</div>
			</div>
		</div>
	);
};
export default UtilitiesBars;
