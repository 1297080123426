import { Tabs as AntdTabs } from "antd";
import qs from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setReduxTab } from "../../app/common";
const { TabPane } = AntdTabs;

export default function useTab({ tabs }) {
	const location = useLocation();
	const dispatch = useDispatch();
	const { search } = location;
	const tabFromUrl = useMemo(() => {
		return qs.parse(search)?.tab;
	}, [search]);

	const searchParam = qs.parse(location.search, {
		parseNumbers: true,
	});

	const [tab, setTab] = useState(() => {
		const values = tabs.map((item) => item.value);
		if (tabFromUrl && values.includes(tabFromUrl)) return tabFromUrl;

		const tabFromProps = tabs.find((item) => item.default)?.value;
		return tabFromProps;
	});

	useEffect(() => {
		if (searchParam.tab !== tab) {
			dispatch(setReduxTab(tab));
		}
	}, [tab, searchParam, dispatch]);

	const TabsArea = useMemo(() => {
		if (tab) {
			return (
				<AntdTabs onChange={setTab} activeKey={tab}>
					{tabs.map((tab) => {
						return <TabPane key={tab.value} tab={tab.label} />;
					})}
				</AntdTabs>
			);
		}

		return null;
		// eslint-disable-next-line
	}, [tab]);

	const Content = useMemo(() => {
		if (tab) {
			return tabs.find((item) => item.value === tab).component;
		}

		return null;
		// eslint-disable-next-line
	}, [tab]);

	return { TabsArea, Content };
}
