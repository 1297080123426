export const POSITION_API_URL = "/v1/positions";
export const CONVERTEDRATE_API_URL = "/v1/converted-rates";
export const RANK_API_URL = "/v1/ranks";
export const MEMBER_TYPE_API_URL = "/v1/member-types";
export const CONTRACT_TYPE_API_URL = "/v1/contract-types";
export const HOLIDAY_API_URL = "/v1/holidays";
export const DEPARTMENT_API_URL = "/v1/departments";

export const EMPLOYEE_API_URL = "/v1/members";
export const SYNC_EMPLOYEES = "/v1/members/ldap/sync";
export const EMPLOYEE_PROJECT_API_URL = "/v1/plans";
export const EMPLOYEE_CAREER_API_URL = "/v1/careers";
export const EMPLOYEE_UPLOAD_API_URL = "/v1/members/avatar";
