import { Col, Form } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormInput, UserSelect } from "../../../../components/Form";
import { Filter } from "../../../../components/Table";
import { names } from "../constants";

export default function HolidayFilter(props) {
	const [form] = Form.useForm();
	const { t } = useTranslation();

	return (
		<Filter {...props} form={form}>
			<Col span={4}>
				<UserSelect
					name={names.manager}
					mode="multiple"
					label={t("department.manager")}
					placeholder={t("common.all")}
				/>
			</Col>
			<Col span={4}>
				<FormInput
					name={names.keyword}
					label={t("common.keyword")}
					placeholder={t("department.keywordPlaceholder")}
				/>
			</Col>
		</Filter>
	);
}
