import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { globalNames } from "../../../global/constants";

export default function useGetIdInfoAndEmployee() {
	const location = useLocation();
	const { id: onParamsId } = useParams();
	const onStoreId = useSelector(
		(state) => state.common.loggedInUser[globalNames.username]
	);
	const isInfo = location.pathname.includes("my-info");

	const id = isInfo ? onStoreId : onParamsId;
	return { id, isInfo };
}
