import { Select } from "antd";
import { isFunction } from "lodash";
import { memo, useCallback, useMemo } from "react";
import { FormItem } from "../";
import { GET_FULL_LIST_PARAMS } from "../../../global/constants";
import { useFetch } from "../../../hooks";
import { getPopupContainer } from "../../../utils/common";
import { getProperty } from "./utils";

function GeneralSelect({
	name,
	label,
	required,
	rules,
	valueKey = "id",
	contentKey,
	hiddenKey,
	disabledKey,
	api,
	placeholder,
	renderContent,
	selectAll,
	form,
	maxTagCount,
	onChange,
	handleResponseData,
	...props
}) {
	const { data, loading } = useFetch({
		fetcher: api,
		params: GET_FULL_LIST_PARAMS,
		handleData: handleResponseData,
	});

	const options = useMemo(() => {
		const options = [];
		if (isFunction(renderContent)) {
			return renderContent(data);
		} else if (Array.isArray(data) && contentKey && valueKey) {
			data.forEach((item) => {
				if (item) {
					const key = getProperty(item, valueKey);
					const label = getProperty(item, contentKey);
					const isHidden = hiddenKey ? getProperty(item, hiddenKey) : false;
					if (!isHidden) {
						options.push({
							...item,
							label,
							key,
							value: key,
							disabled: disabledKey ? getProperty(item, disabledKey) : false,
						});
					}
				}
			});
		}
		return options;
	}, [data, contentKey, valueKey, hiddenKey, disabledKey]);

	const selectPlaceholder = useMemo(() => {
		return (
			placeholder ??
			`Select ${typeof label === "string" ? label.toLowerCase() : ""}`
		);
	}, [label]);
	const selectFilter = useCallback((input, option) => {
		input = input.trim().toLowerCase();
		return option.children?.toLowerCase()?.includes(input);
	}, []);

	return (
		<FormItem
			name={name}
			label={label}
			required={required}
			rules={rules}
			type="select">
			<Select
				showSearch
				allowClear
				showArrow
				filterOption={selectFilter}
				getPopupContainer={getPopupContainer}
				placeholder={selectPlaceholder}
				maxTagCount={
					maxTagCount ?? (props.mode === "multiple" ? "responsive" : undefined)
				}
				loading={loading}
				options={options}
				onChange={onChange}
				{...props}
			/>
		</FormItem>
	);
}

export default memo(GeneralSelect);
