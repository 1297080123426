import { Form } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GeneralDrawer from "../../../../components/Drawer/GeneralDrawer";
import {
	DepartmentSelect,
	FormDescriptionSwitch,
	FormInput,
	RankSelect,
} from "../../../../components/Form";
import Status from "../../components/Status";
import { DEFAULT_STATUS } from "../../components/constants";
import { names, requiredFields } from "../constants";

export default function ModalForm(props) {
	const [form] = Form.useForm();
	const { t } = useTranslation();

	const { initial, isEditMode } = useSelector((state) => state.drawer);

	const fillData = useCallback(() => {
		form.resetFields();
		const init = isEditMode ? initial : {};
		form.setFieldsValue({
			...init,
			status: init.status ?? DEFAULT_STATUS,
			department: init.departmentId,
			[names.isDisplayedRate]: init?.[names.isDisplayedRate] ?? 1,
		});
	}, [form, initial, isEditMode]);

	// const catchCallback = (e) => {
	// 	const msg = e?.response?.data?.message;
	// 	if (msg === backendMessages.position)
	// 		return message.error(t("position.existed"));
	// 	if (msg?.includes(backendMessages.idx))
	// 		return message.error(t("position.idxExists"));
	// };

	return (
		<GeneralDrawer
			{...props}
			form={form}
			fillData={fillData}
			// catchCallback={catchCallback}
			requiredFields={requiredFields}>
			<DepartmentSelect name={names.department} required />
			<FormInput
				name={names.position}
				label={t("position.t")}
				required
				placeholder={t("position.positionPlaceholder")}
				max={50}
			/>
			<FormInput
				name={names.key}
				label={"Key"}
				placeholder={"Eg. PO"}
				max={5}
				required
			/>
			<FormInput
				name={names.order}
				label={t("position.order")}
				customType="number"
				required
				max={4}
				placeholder={t("position.indexPlaceholder")}
			/>
			<RankSelect
				name={names.ranks}
				label={"Rank list"}
				mode="multiple"
				required
			/>
			<FormInput name="description" label="Description" textArea />
			<FormDescriptionSwitch
				name={names.isDisplayedRate}
				label={"Converted rate"}
				style={{ marginTop: 22 }}
				checkedDescription={
					"This position will be displayed on Converted rate table."
				}
				uncheckDescription={
					"This position will NOT be displayed on Converted rate table."
				}
			/>
			<Status />
		</GeneralDrawer>
	);
}
