import { createSlice } from "@reduxjs/toolkit";

export const tableSlice = createSlice({
  name: "table",
  initialState: {
    columns: [],
  },
  reducers: {
    setTableColumns(state, action) {
      state.columns = action.payload;
    },
  },
});

export const { setTableColumns } = tableSlice.actions;

export default tableSlice.reducer;
