import { Breadcrumb } from "antd";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { useTab } from "../../Tab";
import "./styles.scss";

const Title = memo(({ title, breadcrumbs }) => {
	if (title) return <h2>{title}</h2>;
	if (breadcrumbs)
		return (
			<Breadcrumb>
				{breadcrumbs.map((item) => (
					<Breadcrumb.Item key={item.link}>
						<Link to={item.link}>{item.label}</Link>
					</Breadcrumb.Item>
				))}
			</Breadcrumb>
		);
	return null;
});

export default function HeaderLayout({
	title,
	children,
	breadcrumbs,
	tabs,
	button,
}) {
	const { TabsArea, Content } = useTab({ tabs: tabs || [] });

	return (
		<div className="header-layout">
			<div className="layout-header" style={{ paddingBottom: tabs ? 0 : 24 }}>
				<div className="layout-title">
					<Title title={title} breadcrumbs={breadcrumbs} />
					<div className="header-buttons">{button}</div>
				</div>
				{tabs && <div className="layout-tabs">{TabsArea}</div>}
			</div>
			<div className="header-layout-content">{tabs ? Content : children}</div>
		</div>
	);
}
