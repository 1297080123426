import { configureStore } from "@reduxjs/toolkit";
import drawerReducer from "../components/Drawer/slice/drawer";
import tableReducer from "../components/Table/Slice/tableSlice";
import commonReducer from "./common";
export const store = configureStore({
  reducer: {
    common: commonReducer,
    drawer: drawerReducer,
    table: tableReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
