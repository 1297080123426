import React, { useEffect } from "react";
import { HeaderLayout } from "../../../components/Layouts";
import memberTypeApi from "../../../api/memberTypeApi";
import { TableMain, useTable } from "../../../components/Table";
import { cols } from "./constants";
import { useTranslation } from "react-i18next";
import ModalForm from "./components/ModalForm";

export default function MemberType() {
	const { t } = useTranslation();

	const { items, filter, setFilter, loadingTable, fetchData, totalRecord } =
		useTable({
			getApi: memberTypeApi.getAll,
		});

	return (
		<HeaderLayout title={t("memberType.mainTitle")}>
			<ModalForm
				fetchData={fetchData}
				addApi={memberTypeApi.add}
				editApi={memberTypeApi.edit}
				isPagination={false}
				setFilter={setFilter}
			/>
			<TableMain
				items={items}
				cols={cols}
				filter={filter}
				setFilter={setFilter}
				fetchData={fetchData}
				getApi={memberTypeApi.getAll}
				deleteManyApi={memberTypeApi.deleteMany}
				loadingTable={loadingTable}
				deleteContentKey="memberType"
				totalRecord={totalRecord}
				disablePagination
				excelName="list-memberType"
				title={t("memberType.memberType")}
			/>
		</HeaderLayout>
	);
}
