import axiosClient from "./axiosClient";
import { CONTRACT_TYPE_API_URL } from "./constants";
const contractTypeApi = {
	getAll(params) {
		return axiosClient.get(CONTRACT_TYPE_API_URL, { params });
	},
	getById(id) {
		return axiosClient.get(`${CONTRACT_TYPE_API_URL}/${id}`);
	},
	add(data) {
		return axiosClient.post(CONTRACT_TYPE_API_URL, data);
	},
	edit(id, data) {
		return axiosClient.put(`${CONTRACT_TYPE_API_URL}/${id}`, data);
	},
	getById(id) {
		return axiosClient.get(`${CONTRACT_TYPE_API_URL}/${id}`);
	},
	deleteMany(ids) {
		return axiosClient.delete(`${CONTRACT_TYPE_API_URL}`, {
			data: {
				ids,
			},
		});
	},
};

export default contractTypeApi;
