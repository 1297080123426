import React from "react";
import { Button as AntdButton } from "antd";
import {
	CameraFilled,
	CloseOutlined,
	EditOutlined,
	PlusOutlined,
	SaveOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const definedIcons = {
	create: <PlusOutlined />,
	edit: <EditOutlined />,
	save: <SaveOutlined />,
	cancel: <CloseOutlined />,
	upload: <CameraFilled />,
};

export function Button({
	color,
	icon,
	children,
	className,
	uppercase = false,
	...rest
}) {
	if (icon && typeof icon === "string" && definedIcons[icon]) {
		icon = definedIcons[icon];
	}

	return (
		<AntdButton
			{...rest}
			className={`custom-button ${className} ${uppercase ? "uppercase" : ""}`}
			icon={icon}>
			{children}
		</AntdButton>
	);
}

const CreateButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Button
			type="primary"
			icon="create"
			uppercase
			children={children || t("common.create")}
			{...props}
		/>
	);
};

const SaveButton = (props) => {
	const { t } = useTranslation();
	return (
		<Button
			type="primary"
			icon="save"
			children={t("common.save")}
			uppercase
			{...props}
		/>
	);
};

const EditBlueButton = (props) => (
	<Button {...props} type="primary" icon="edit" />
);

const SaveBlueButton = (props) => (
	<Button {...props} type="primary" icon="save" />
);

const CancelWhiteButton = (props) => (
	<Button {...props} type="ghost" icon="cancel" />
);

const NoBorderButton = (props) => <Button {...props} type="text" />;
const UpLoadButton = (props) => {
	const { t } = useTranslation();
	return (
		<Button
			type="white"
			icon="upload"
			children={t("common.selectAvatar")}
			uppercase
			{...props}
		/>
	);
};
export {
	CreateButton,
	SaveButton,
	EditBlueButton,
	SaveBlueButton,
	CancelWhiteButton,
	NoBorderButton,
	UpLoadButton,
};
