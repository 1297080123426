import { Col, Form } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
	DepartmentSelect,
	FormInput,
	PositionSelect,
	RangePicker,
	RankSelect,
} from "../../../../components/Form";
import { Filter } from "../../../../components/Table";
import { VN_DATE_FORMAT } from "../../../../global/constants";
import { names } from "../constant";
import moment from "moment";

function ListFilter(props) {
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const submitValues = useCallback((values) => {
		if (values.time) {
			values[names.startDate] = values.time[0].format(VN_DATE_FORMAT);
			values[names.endDate] = values.time[1].format(VN_DATE_FORMAT);
		} else {
			delete values[names.startDate];
			delete values[names.endDate];
		}
		delete values.time;
		return values;
	}, []);

	const initialValues = useCallback((values) => {
		const startDate = values[names.startDate];
		const endDate = values[names.endDate];
		const obj = {};

		if (startDate && endDate) {
			obj.time = [
				moment(startDate, VN_DATE_FORMAT),
				moment(endDate, VN_DATE_FORMAT),
			];
		}

		return { values, ...obj };
	}, []);

	return (
		<Filter
			{...props}
			form={form}
			submitValues={submitValues}
			initialValues={initialValues}>
			<Col span={4}>
				<RangePicker name={"time"} label={t("employees.startDate")} />
			</Col>
			<Col span={4}>
				<DepartmentSelect
					name={names.department}
					placeholder={t("employees.departmentPlaceholder")}
					mode="multiple"
				/>
			</Col>
			<Col span={4}>
				<PositionSelect name={names.position} mode="multiple" />
			</Col>
			<Col span={4}>
				<RankSelect name={names.rank} mode="multiple" />
			</Col>
			<Col span={4}>
				<FormInput
					name={names.keyword}
					label={t("common.keyword")}
					placeholder={t("employees.keywordListPlaceholder")}
				/>
			</Col>
		</Filter>
	);
}

export default ListFilter;
