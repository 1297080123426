import { isFunction } from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getPopupContainer } from "../../../utils";
import {
	DeleteOutlined,
	EditOutlined,
	EllipsisOutlined,
	InfoCircleOutlined,
} from "@ant-design/icons";
import { Button, Popover } from "antd";

export default function useActionColumn({
	onEdit,
	onDelete,
	onDetail,
	menuRender,
	extraButtons,
	...actionColProps
}) {
	const { t } = useTranslation();

	return useMemo(
		() => {
			const hasDetail = isFunction(onDetail);
			const hasEdit = isFunction(onEdit);
			const hasDelete = isFunction(onDelete);
			if (!(hasDetail || hasEdit || hasDelete || menuRender)) {
				return undefined;
			}
			return {
				title: t("common.action"),
				width: 80,
				align: "center",
				render: (_, record) => {
					return (
						<Popover
							placement="bottom"
							content={
								isFunction(menuRender) ? (
									menuRender(record)
								) : (
									<div className="action-content">
										{hasDetail && (
											<Button
												icon={<InfoCircleOutlined />}
												onClick={() => onDetail(record)}>
												{t("common.detail")}
											</Button>
										)}
										{hasEdit && (
											<Button
												icon={<EditOutlined />}
												onClick={() => onEdit(record)}>
												{t("common.edit")}
											</Button>
										)}
										{hasDelete && (
											<Button
												icon={<DeleteOutlined />}
												onClick={() => onDelete(record)}>
												{t("common.delete")}
											</Button>
										)}
										{extraButtons}
									</div>
								)
							}
							trigger="hover"
							overlayClassName="overlayActionContent"
							getTooltipContainer={getPopupContainer}
							getPopupContainer={getPopupContainer}>
							<span className="action-icon">
								<EllipsisOutlined />
							</span>
						</Popover>
					);
				},
				show: true,
				export: false,
				onCell: () => {
					return {
						onDoubleClick: (e) => {
							e.stopPropagation();
						},
					};
				},
				...actionColProps,
			};
		},
		// eslint-disable-next-line
		[
			menuRender,
			// actionColProps,
			extraButtons,
			onDetail,
			onEdit,
			onDelete,
			t,
		]
	);
}
