import { Avatar, Form, Upload } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { UpLoadButton } from "../../../../components/Buttons";
import { convertKbToMB, maxSizeImage, typeFilePngAndJpeg } from "../constants";

export default function UploadAvatar({ dataInfo }) {
	const { t } = useTranslation();
	const { visible } = useSelector((state) => state.common);
	const [image, setImage] = useState();
	const [errorUpload, setErrorUpload] = useState();
	useEffect(() => {
		if (dataInfo?.avatarUri) {
			setImage(dataInfo?.avatarUri);
		}
	}, [dataInfo?.avatarUri]);
	const normFile = ({ file }) => {
		return file;
	};
	return (
		<div className="profile_avatar">
			<Avatar
				size={250}
				src={image}
				className={`profile_avatar-Image ${visible}`}
			/>
			<div className={`button_upLoad ${visible}`}>
				<Form.Item
					name="uploadAvatar"
					valuePropName="file"
					getValueFromEvent={normFile}>
					<Upload
						accept={typeFilePngAndJpeg}
						showUploadList={false}
						beforeUpload={(file) => {
							const isLt10M = file.size / convertKbToMB < maxSizeImage;
							if (!isLt10M) {
								setErrorUpload(t("validateMsg.errorUploadImage"));
							} else {
								setImage(
									process.env.REACT_APP_BASE_IMAGE_URL + `/system/${file.name}`
								);
								setErrorUpload();
							}
							return isLt10M;
						}}>
						<UpLoadButton />
					</Upload>
				</Form.Item>
			</div>
			<div
				className={`error_ImageUpload ${visible}`}
				style={{
					color: "red",
					fontSize: 12,
					position: "absolute",
					bottom: -20,
				}}>
				{errorUpload}
			</div>
		</div>
	);
}
