import { Tag } from "antd";
import i18n from "../../../translation/i18n";
import { statusRadio } from "../components/constants";
import { find } from "lodash";

export const names = {
  position: "position",
  status: "status",
  keyword: "keyword",
  department: "department",
  description: "description",
  ranks: "rank",
  key: "key",
  isDisplayedRate: "display",
  order: "order",
};

export const cols = [
  {
    type: "title",
    title: "Position",
    dataIndex: names.position,
    width: "20%",
  },
  { type: names.description },
  {
    type: "all",
    dataIndex: names.department,
    title: "Department",
    width: "15%",
  },
  {
    type: names.status,
    render: (value, record) => {
      const tag = find(statusRadio, { value: value });
      return (
        <Tag value={tag?.value} color={tag?.color}>
          {tag?.label}
        </Tag>
      );
    },
  },
  {
    type: "all",
    title: i18n.t("position.order"),
    dataIndex: names.order,
    width: 70,
  },
];

export const backendMessages = {
  idx: "Order already exists",
  position: "Position already exists",
};

export const requiredFields = [
  names.department,
  names.position,
  names.order,
  names.ranks,
  names.key,
];
