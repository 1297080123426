import { SyncOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import employeeApi from "../../../../api/employeeApi";

function SyncBtn({ refetch }) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	const handleSync = useCallback(async () => {
		setLoading(true);
		try {
			await employeeApi.syncUser();
			message.success(t("message.success"));
			refetch?.();
		} catch (error) {
			message.error(t("message.errorSync"));
		}
		setLoading(false);
	}, [t]);
	return (
		<Button
			loading={loading}
			icon={<SyncOutlined />}
			onClick={handleSync}
			type="primary">
			{t("common.syncLdap")}
		</Button>
	);
}

export default SyncBtn;
