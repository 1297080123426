import axiosClient from "./axiosClient";
import { RANK_API_URL } from "./constants";
const rankApi = {
	getAll(params) {
		return axiosClient.get(RANK_API_URL, { params });
	},
	getById(id) {
		return axiosClient.get(`${RANK_API_URL}/${id}`);
	},
	add(data) {
		return axiosClient.post(RANK_API_URL, data);
	},
	edit(id, data) {
		return axiosClient.put(`${RANK_API_URL}/${id}`, data);
	},
	getById(id) {
		return axiosClient.get(`${RANK_API_URL}/${id}`);
	},
	deleteMany(ids) {
		return axiosClient.delete(`${RANK_API_URL}`, {
			data: {
				ids,
			},
		});
	},
};

export default rankApi;
