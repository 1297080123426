import React, { memo } from "react";
import { HeaderLayout } from "../../../components/Layouts";
import EmployeeCareer from "../../Employee/Career";
import EmployeeGeneral from "../../Employee/General";
import EmployeeProject from "../../Employee/Project";
import { getBreadcrumbs } from "./constants";
import { useTranslation } from "react-i18next";
import ChangePassword from "../ChangePassword";
import useGetIdInfoAndEmployee from "../../Employee/hooks/useGetIdInfoAndEmployee";
import { useGetDetailEmployee } from "../../../hooks";

export default memo(function MyInfoLayout() {
	const { id } = useGetIdInfoAndEmployee();

	const { t } = useTranslation();
	const { data: employee } = useGetDetailEmployee(id);

	const breadcrumbs = getBreadcrumbs(id, employee.fullName);

	return (
		<HeaderLayout
			breadcrumbs={breadcrumbs}
			tabs={[
				{
					value: "general",
					default: true,
					label: t("common.general"),
					component: <EmployeeGeneral employee={employee} />,
				},
				{
					value: "project",
					label: t("employeeLayout.project"),
					component: <EmployeeProject employee={employee} />,
				},
				{
					value: "career",
					label: t("employeeLayout.career"),
					component: <EmployeeCareer employee={employee} />,
				},
				{
					value: "password",
					label: "Password",
					component: <ChangePassword employee={employee} />,
				},
			]}
		/>
	);
});
