import { Form, message } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GeneralDrawer from "../../../../components/Drawer/GeneralDrawer";
import { FormDatePicker, FormInput } from "../../../../components/Form";
import { VN_DATE_FORMAT } from "../../../../global/constants";
import { DEFAULT_STATUS } from "../../components/constants";
import Status from "../../components/Status";
import { backendMessages, names, requiredFields } from "../constants";
import moment from "moment";

export default function ModalForm(props) {
	const [form] = Form.useForm();
	const { t } = useTranslation();

	const { initial, isEditMode } = useSelector((state) => state.drawer);

	const fillData = useCallback(() => {
		form.resetFields();
		const init = isEditMode ? { ...initial } : {};
		if (isEditMode) {
			init.date = moment(init.date, VN_DATE_FORMAT);
		}
		form.setFieldsValue({
			...init,
			status: init.status ?? DEFAULT_STATUS,
		});
	}, [form, initial, isEditMode]);

	const transformValues = useCallback((values) => {
		const date = values.date;
		if (date) {
			values.date = date.format(VN_DATE_FORMAT);
		}
		return values;
	}, []);

	const catchCallback = useCallback(
		(e) => {
			const msg = e?.response?.data?.message;
			if (msg?.includes(backendMessages.exists)) {
				return message.error(t("holiday.dateExists"));
			}
		},
		[t]
	);

	return (
		<GeneralDrawer
			{...props}
			form={form}
			fillData={fillData}
			transformValues={transformValues}
			catchCallback={catchCallback}
			requiredFields={requiredFields}>
			<FormDatePicker name={names.date} label={t("common.date")} required />
			<FormInput
				name={names.description}
				label={t("common.description")}
				textArea
			/>
			<Status />
		</GeneralDrawer>
	);
}
