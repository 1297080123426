import { isFunction } from "lodash";
import { useEffect, useState } from "react";

export default function useFetch({
	fetcher,
	params,
	defaultData = {},
	dependencies,
	handleData = (res) => res.data?.data,
}) {
	const [data, setData] = useState(defaultData);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchApi = async () => {
			if (isFunction(fetcher)) {
				setLoading(true);
				try {
					const response = await fetcher(params);
					const data = handleData(response);
					setData(data);
				} catch (e) {
					console.log("e :", e);
				}
				setLoading(false);
			}
		};
		fetchApi();
		//eslint-disable-next-line
	}, [params, dependencies]);

	return { data, setData, loading };
}
