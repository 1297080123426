import axiosClient from "./axiosClient";
import { EMPLOYEE_PROJECT_API_URL } from "./constants";
const employeeProjectApi = {
	getAll(params, id) {
		return axiosClient.get(`${EMPLOYEE_PROJECT_API_URL}/${id}`, { params });
	},
	getAllStandard(id) {
		return (params) => this.getAll(params, id);
	},
};

export default employeeProjectApi;
