export const MAX_INPUT_LEN = 200;
export const MAX_INPUT_LEN_50 = 50;
export const MIN_INPUT = 1;
export const REQUIRED_STRING_200 = {
  required: true,
  type: "string",
  min: MIN_INPUT,
  max: MAX_INPUT_LEN,
};
export const REQUIRED_STRING_50 = {
  required: true,
  type: "string",
  min: MIN_INPUT,
  max: MAX_INPUT_LEN_50,
};
