import i18n from "../../../translation/i18n";

export const getBreadcrumbs = (id, fullName) => [
	{
		link: "/my-info",
		label: "My Info",
	},
	{
		link: "/my-info",
		label: fullName,
	},
];
