import i18n from "../../../translation/i18n";
import { find } from "lodash";
import { statusRadio } from "../components/constants";
import { Tag } from "antd";

export const names = {
  time: "time",
  startDate: "from",
  endDate: "to",
  date: "date",
  description: "description",
  keyword: "keyword",
  status: "status",
};

export const cols = [
  {
    type: "all",
    title: i18n.t("common.date"),
    dataIndex: names.date,
  },
  { type: names.description },
  {
    type: names.status,
    render: (value, record) => {
      const tag = find(statusRadio, { value: value });
      return (
        <Tag value={tag?.value} color={tag?.color}>
          {tag?.label}
        </Tag>
      );
    },
  },
];

export const backendMessages = {
  exists: "exists",
};

export const requiredFields = [names.date];
