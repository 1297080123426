import { Tag } from "antd";
import i18n from "../../../translation/i18n";
import { statusRadio } from "../components/constants";
import { find } from "lodash";

export const cols = [
  {
    type: "title",
    title: i18n.t("contractType.contractType"),
    dataIndex: "contractType",
    width: "20%",
  },
  { type: "description" },
  {
    type: "status",
    render: (value, record) => {
      const tag = find(statusRadio, { value: value });
      return (
        <Tag value={tag?.value} color={tag?.color}>
          {tag?.label}
        </Tag>
      );
    },
  },
];

export const backendMessages = {
  contractType: "Contract type already exists",
};

export const requiredFields = ["contractType"];
