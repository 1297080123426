import { Tooltip } from "antd";
import React from "react";
import { getPopupContainer } from "../../../../../utils";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import { NoBorderButton } from "../../../../Buttons";

function TooltipBtn({ title, onClick, icon }) {
	return (
		<Tooltip title={title} color="blue" getPopupContainer={getPopupContainer}>
			<NoBorderButton onClick={onClick} icon={icon}></NoBorderButton>
		</Tooltip>
	);
}

export function FullScreenButton({ handleFullScreen }) {
	const { isFullscreen } = useSelector((state) => state.common);
	const { t } = useTranslation();

	const handleClick = () => {
		if (isFullscreen) handleFullScreen.exit();
		else handleFullScreen.enter();
	};

	return (
		<TooltipBtn
			title={t("table.fullscreen")}
			onClick={handleClick}
			icon={
				isFullscreen ? (
					<FullscreenExitOutlined className="icon-md" />
				) : (
					<FullscreenOutlined className="icon-md" />
				)
			}
		/>
	);
}

export default TooltipBtn;
