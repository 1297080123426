import { EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Button as AntdButton, Form } from "antd";
import React from "react";
export default function SubmitBtn({
	form,
	children,
	style,
	className,
	requiredFields = [],
	onClick,
	disabled,
	loading,
	Button,
	normalBtn = false,
	fieldsToCheck = () => "normal",
	create = true,
	icon,
}) {
	const formItemProps = { style, className };
	const submitBtnProps = { onClick, loading };
	if (!normalBtn) submitBtnProps.htmlType = "submit";
	const g = form.getFieldValue;
	let requireCondition = false;
	let errorCondition = false;
	const normalCheck = () => {
		requireCondition = requiredFields.some(
			(field) =>
				[undefined, null, ""].includes(g(field)) || g(field)?.length === 0
		);

		// Check required condition
		// requiredFields.forEach(field => {
		//   console.log(field, g(field));
		// });

		const errors = form.getFieldsError();
		errorCondition = errors.some((field) => field.errors.length > 0);
	};
	const noCheck = () => {
		requireCondition = false;
		errorCondition = false;
	};
	const specificCheck = () => {
		console.log("do something");
	};

	const ButtonComponent = Button || AntdButton;
	return (
		<Form.Item noStyle shouldUpdate>
			{() => {
				const checkFields = fieldsToCheck();

				if (checkFields === "normal") normalCheck();
				if (checkFields === "noCheck") noCheck();
				if (typeof checkFields === "object") specificCheck();
				return (
					<Form.Item
						{...formItemProps}
						className={"submit-btn " + (className || "")}>
						<ButtonComponent
							{...submitBtnProps}
							type="primary"
							icon={
								icon ? icon : create ? <PlusCircleFilled /> : <EditOutlined />
							}
							disabled={requireCondition || errorCondition || disabled}
							uppercase>
							{children}
						</ButtonComponent>
					</Form.Item>
				);
			}}
		</Form.Item>
	);
}
