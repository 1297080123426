import { EyeOutlined } from "@ant-design/icons";
import { Checkbox, Dropdown } from "antd";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { deepCopy, getPopupContainer } from "../../../../../utils";
import { setTableColumns } from "../../../Slice/tableSlice";
import TooltipBtn from "./TooltipBtn";

function ToggleVisibleCols() {
	const { t } = useTranslation();
	const [visibleDropdown, setVisibleDropdown] = useState(false);
	const { columns } = useSelector((state) => state.table);
	const dispatch = useDispatch();

	const handleChangeVisibleCheckbox = useCallback(
		(e, id) => {
			const newColumns = deepCopy(columns);
			dispatch(
				setTableColumns(
					newColumns.map((col) => {
						if (col.id === id) {
							col.show = e.target.checked;
						}
						return col;
					})
				)
			);
		},
		[dispatch, columns]
	);

	const items = useMemo(
		() =>
			columns
				.filter((col) => col.export && !col.notToggle)
				.map((col, i) => {
					return {
						key: i,
						label: (
							<Checkbox
								onChange={(e) => handleChangeVisibleCheckbox(e, col.id)}
								value={col.id}
								checked={col.show}>
								{col.title}
							</Checkbox>
						),
					};
				}),
		// eslint-disable-next-line
		[handleChangeVisibleCheckbox]
	);

	return (
		<Dropdown
			menu={{ items }}
			trigger={["click"]}
			open={visibleDropdown}
			onOpenChange={setVisibleDropdown}
			getPopupContainer={getPopupContainer}
			placement="bottomRight">
			<TooltipBtn
				title={t("table.toggleColumn")}
				icon={<EyeOutlined className="icon-md" />}
			/>
		</Dropdown>
	);
}

export default memo(ToggleVisibleCols);
