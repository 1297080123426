import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";

export default function useListenChangeParams() {
	const { tab, filter } = useSelector((state) => state.common);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		navigate({
			pathname: location.pathname,
			search: qs.stringify(
				{ ...filter, tab },
				{
					skipNull: true,
					skipEmptyString: true,
				}
			),
		});
	}, [tab, filter]);
	return {};
}
