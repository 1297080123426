export const itemsWithDefined = (defined, items) => {
	return items.map((item, i) => {
		if (typeof item === "string") {
			const definedItem = defined[item];
			if (definedItem) {
				const Component = definedItem;
				return <Component key={i} />;
			}
			return null;
		}
		const Component = item;
		return <Component key={i} />;
	});
};
