import { find } from "lodash";
import i18n from "../../../translation/i18n";
import { statusRadio } from "../components/constants";
import { Tag } from "antd";

export const names = {
  order: "order",
};

export const cols = [
  {
    type: "title",
    title: i18n.t("rank.t"),
    dataIndex: "rank",
    width: "20%",
  },
  { type: "description" },
  {
    type: "status",
    render: (value, record) => {
      const tag = find(statusRadio, { value: value });
      return (
        <Tag value={tag?.value} color={tag?.color}>
          {tag?.label}
        </Tag>
      );
    },
  },
  {
    type: "all",
    title: i18n.t("position.order"),
    dataIndex: names.order,
    width: 70,
  },
];

export const backendMessages = {
  idx: "Order already exists",
  position: "Rank already exists",
};

export const requiredFields = ["rank", names.order];
