import { Form, InputNumber } from "antd";
import React, { useEffect } from "react";

const ChildrenTable = ({ dataSource }) => {
	const { list } = dataSource;
	const form = Form.useFormInstance();
	useEffect(() => {
		list.map((item) => {
			return form.setFieldsValue({ [item?.id]: item?.value });
		});
		//missing `list`
		// eslint-disable-next-line
	}, [form]);

	return (
		<>
			<div className="Table_children">
				{list.map((item) => {
					return (
						<div className={`rank`} key={item?.rank}>
							<div className="rank_Title">{item?.rank}</div>
							<div className="rank_content">
								<Form.Item
									key={item?.id}
									noStyle
									name={item?.id}
									shouldUpdate
									// normalize={convertInputCellValidate}
									rules={[
										{
											required: true,
										},
									]}>
									<InputNumber
										key={item?.id}
										maxLength={6}
										style={{
											width: "90px",
											height: "32px",
											textAlign: "center",
										}}
									/>
								</Form.Item>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};
export default ChildrenTable;
