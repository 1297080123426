import { createSlice } from "@reduxjs/toolkit";
import { globalNames } from "../global/constants";

export const commonSlice = createSlice({
	name: "common",
	initialState: {
		isFullscreen: false,
		visible: false,
		tab: "",
		loggedInUser: {
			[globalNames.username]: "longdv",
		},
		filter: {},
	},
	reducers: {
		setIsFullscreen(state, action) {
			state.isFullscreen = action.payload;
		},
		setVisible(state, action) {
			state.visible = action.payload;
		},
		setReduxTab(state, action) {
			state.tab = action.payload;
		},
		setTableFilter(state, action) {
			state.filter = action.payload;
		},
		setLoggedInUser(state, action) {
			state.loggedInUser = action.payload;
		},
	},
});

export const {
	setIsFullscreen,
	setVisible,
	setReduxTab,
	setTableFilter,
	setLoggedInUser,
} = commonSlice.actions;

export default commonSlice.reducer;
