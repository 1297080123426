import { Tag } from "antd";
import i18n from "../../../translation/i18n";
import { find } from "lodash";
import { statusRadio } from "../components/constants";

export const names = {
  department: "department",
  description: "description",
  keyword: "keyword",
  manager: "manager",
  managerId: "managerId",
  status: "status",
};

export const cols = [
  {
    type: "title",
    title: i18n.t("department.department"),
    dataIndex: names.department,
    width: "20%",
  },
  {
    type: "allWithoutSort",
    title: i18n.t("department.manager"),
    dataIndex: names.manager,
    width: "20%",
  },
  { type: names.description },
  {
    type: names.status,
    render: (value, record) => {
      const tag = find(statusRadio, { value: value });
      return (
        <Tag value={tag?.value} color={tag?.color}>
          {tag?.label}
        </Tag>
      );
    },
  },
];

export const backendMessages = {
  exists: "exists",
};

export const requiredFields = [names.department, names.manager];
