import { t } from "i18next";
import * as yup from "yup";

export const FORMAT_DATE = "DD-MM-YYYY";
export const IdCardRegExp = /^((\d{9})|(\d{12}))$/;
export const bankAccRegExp = /^(\d{4,16})$/;
export const phoneRegExp = /^(\+84|0)([0-9]{9})\b$/;
export const convertKbToMB = 1024 * 1024;
export const maxSizeImage = 10;
export const typeFilePngAndJpeg = "image/png, image/jpeg";
export const disabledFields = [
  "position",
  "rank",
  "fullName",
  "id",
  "potentialPosition",
  "emailCompany",
];
export const schema = yup.object().shape({
  emailPersonal: yup
    .string()
    .ensure(true)
    .trim()
    .email(t("validateMsg.invalidInformation"))
    .max(200),
  phoneNumber: yup
    .string()
    .ensure(true)
    .trim()
    .matches(phoneRegExp, t("validateMsg.invalidInformation")),
  identityCard: yup
    .string()
    .ensure(true)
    .trim()
    .matches(IdCardRegExp, t("validateMsg.invalidInformation")),
  bankAccount: yup
    .string()
    .ensure(true)
    .trim()
    .matches(bankAccRegExp, t("validateMsg.invalidInformation")),
});
export const keys = [
  "fullName",
  "id",
  "alias",
  "emailCompany",
  "phoneNumber",
  "position",
  "rank",
  "potentialPosition",
  "dateOfBirth",
  "identityCard",
  "dateOfIssue",
  "placeOfIssue",
  "placeOfResidence",
  "placeOfSojourn",
  "bank",
  "contractType",
  "memberType",
  "bankAccount",
  "bankBranch",
  "emailPersonal",
  "viFullName",
];
export const INVALID_DATEPICKER = "Invalid date";
