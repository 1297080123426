import { DatePicker } from "antd";
import React from "react";
import { VN_DATE_FORMAT } from "../../../global/constants";
import FormItem from "../FormItem";

export default function FormDatePicker(props) {
	const {
		name,
		required,
		label,
		style,
		className,
		disabled,
		placeholder,
		hasFeedBack,
		formItemProps: itemProps = {},
		...rest
	} = props;
	const lowerLabel = label.toLowerCase();

	const formItemProps = {
		name,
		required,
		label,
		style,
		className,
		hasFeedBack,
		type: "select",
		...itemProps,
	};
	const datePickerProps = {
		disabled,
		getPopupContainer: (triggerNode) => triggerNode.parentNode,
	};
	datePickerProps.placeholder = placeholder || `Select ${lowerLabel}`;
	return (
		<FormItem {...formItemProps}>
			<DatePicker
				format={VN_DATE_FORMAT}
				className="w-full"
				{...datePickerProps}
				{...rest}
			/>
		</FormItem>
	);
}
