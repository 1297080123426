import { createSlice } from "@reduxjs/toolkit";

export const drawerSlice = createSlice({
	name: "drawer",
	initialState: {
		isOpened: false,
		locale: {
			title: "",
			btn: "",
			successMsg: "",
			icon: undefined,
		},
		isEditMode: false,
		initial: {},
	},
	reducers: {
		openDrawer(state, { payload }) {
			state.isOpened = true;
			state.initial = payload;
			state.isEditMode = Boolean(payload);
		},
		setLocale(state, { payload }) {
			state.locale = payload;
		},
		closeDrawer(state) {
			state.isOpened = false;
			state.initial = {};
		},
	},
});

export const { openDrawer, closeDrawer, setLocale } = drawerSlice.actions;

export default drawerSlice.reducer;
