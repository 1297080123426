import { Form, message } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GeneralDrawer from "../../../../components/Drawer/GeneralDrawer";
import { FormInput } from "../../../../components/Form";
import Status from "../../components/Status";
import { DEFAULT_STATUS } from "../../components/constants";
import { backendMessages, requiredFields } from "../constants";

export default function ModalForm(props) {
	const [form] = Form.useForm();
	const { t } = useTranslation();

	const { initial, isEditMode } = useSelector((state) => state.drawer);

	const fillData = useCallback(() => {
		form.resetFields();
		const init = isEditMode ? initial : {};
		form.setFieldsValue({
			...init,
			status: init.status ?? DEFAULT_STATUS,
		});
	}, [form, initial, isEditMode]);

	const catchCallback = useCallback(
		(e) => {
			const msg = e?.response?.data?.message;
			if (msg === backendMessages.memberType)
				return message.error(t("memberType.memberTypeExists"));
		},
		[t]
	);

	return (
		<GeneralDrawer
			{...props}
			form={form}
			fillData={fillData}
			catchCallback={catchCallback}
			requiredFields={requiredFields}>
			<FormInput
				name="memberType"
				label={t("memberType.memberType")}
				required
				placeholder={t("memberType.memberTypePlaceholder")}
				max={20}
			/>
			<FormInput name="description" label="Description" textArea />
			<Status />
		</GeneralDrawer>
	);
}
