import i18n from "../../../translation/i18n";

export const names = {
	project: "project",
	key: "key",
	position: "position",
	startDate: "startDate",
	endDate: "endDate",
	rank: "rank",
	worked: "worked",
	keyword: "keyword",
	departmentL: "Department",
};

export const cols = [
	{ type: "nth" },
	{
		type: "title",
		title: i18n.t("employeeProject.project"),
		dataIndex: names.project,
		width: "20%",
	},
	{ type: "allWithoutSort", title: i18n.t("common.key"), dataIndex: names.key },
	{
		type: "allWithoutSort",
		title: i18n.t("position.t"),
		dataIndex: names.position,
	},
	{ type: "allWithoutSort", title: i18n.t("rank.t"), dataIndex: names.rank },
	{
		type: "allWithoutSort",
		title: i18n.t("employeeProject.hour"),
		dataIndex: names.worked,
		align: "center",
		width: 150,
	},
	{
		type: "all",
		title: i18n.t("time.from"),
		dataIndex: names.startDate,
	},
	{ type: "all", title: i18n.t("time.to"), dataIndex: names.endDate },
];
