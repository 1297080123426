import axiosClient from "./axiosClient";
import { CONVERTEDRATE_API_URL } from "./constants";
const convertedRateApi = {
  getAll(params) {
    return axiosClient.get(CONVERTEDRATE_API_URL, { params });
  },
  edit(data) {
    return axiosClient.put(`${CONVERTEDRATE_API_URL}`, data);
  },
};

export default convertedRateApi;
